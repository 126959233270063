<template>
  <main>
    <div class="form-container">
      <h2>Demande de réinitialisation de mot de passe</h2>
      <div class="form-group">
        <label for="email" class="form-label">E-mail:</label>
        <input v-model="email" type="email" id="email" class="form-input" />
        <button @click="sendEmail()" class="primary-button">Envoyer</button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from "vuex";
import { showToast } from "@/sweetAlertService";

export default {
  name: "PasswordResetRequest",
  data() {
    return {
      email: "",
    };
  },
  methods: {
    ...mapActions({
      sendResetPasswordEmail: "sendResetPasswordEmail",
    }),
    sendEmail() {
      // Vérifie que l'e-mail n'est pas vide
      if (this.email === "") {
        showToast("error", "L'e-mail ne peut pas être vide");
        return;
      }

      // Vérifie que le champ contient bien un email
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.email)) {
        showToast("error", "Veuillez entrer un email valide");
        return;
      }

      this.sendResetPasswordEmail({
        email: this.email,
      });
    },
  },
};
</script>

<style scoped>
main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.form-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 450px;
  width: 100%;
  text-align: center;
}

h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-label {
  margin-bottom: 10px;
  font-weight: bold;
  color: #555;
}

input,
select {
  margin-bottom: 10px;
  background-color: white;
  border-radius: 2px;
  padding: 10px;
  border: 1px solid var(--color-border);
  width: 94.5%;
}

input:focus,
select:focus {
  outline: none !important;
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 2px var(--color-primary);
}
</style>
