<template>
  <div class="machine-manager">
    <h2>Machines</h2>
    <div class="filter-options">
      <div class="filter-data">
        <label for="name-filter">Filtrer par nom:</label>
        <input
          id="name-filter"
          v-model="filters.name"
          @input="filterMachines"
        />
      </div>
      <div class="filter-data">
        <label for="client-filter">Filtrer par client:</label>
        <select
          id="client-filter"
          v-model="filters.client"
          @change="filterMachines"
        >
          <option value="">Tous</option>
          <option v-for="client in clients" :key="client.id" :value="client.id">
            {{ client.name }}
          </option>
        </select>
      </div>
      <div class="filter-data">
        <label for="type-filter">Filtrer par type:</label>
        <select
          id="type-filter"
          v-model="filters.type"
          @change="filterMachines"
        >
          <option value="">Tous</option>
          <option v-for="type in types" :key="type.id" :value="type.id">
            {{ type.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="list-header">
      <span class="header-item" @click="sortMachines('name')"
        >Nom de la Machine</span
      >
      <span class="header-item" @click="sortMachines('client')">Client</span>
      <span class="header-item" @click="sortMachines('type')">Type</span>
      <span class="header-item">Actions</span>
    </div>
    <ul class="machine-list">
      <li
        v-for="machine in filteredAndSortedMachines"
        :key="machine.id"
        @click="selectMachine(machine)"
        :title="machine.description"
      >
        <span class="machine-item">{{ machine.name }}</span>
        <span class="machine-item">{{ getClientName(machine.client_id) }}</span>
        <span class="machine-item">{{ getTypeName(machine.type_id) }}</span>
        <span class="machine-item">
          <button class="delete-button" @click.stop="confirmDelete(machine.id)">
            Supprimer
          </button>
        </span>
      </li>
    </ul>
    <h3 v-if="filteredAndSortedMachines.length === 0">
      Aucune machine trouvée
    </h3>

    <modal v-if="showModal" @close="closeModal">
      <h3>
        {{
          selectedMachine
            ? "Modifier la Machine"
            : "Ajouter une Nouvelle Machine"
        }}
      </h3>
      <div>
        <label>Nom de la Machine:</label>
        <input v-model="machineForm.name" maxlength="254" />
      </div>
      <div>
        <label>Description:</label>
        <input v-model="machineForm.description" maxlength="254" />
      </div>
      <div>
        <label>Client:</label>
        <select v-model="machineForm.client_id">
          <option v-for="client in clients" :key="client.id" :value="client.id">
            {{ client.name }}
          </option>
        </select>
      </div>
      <div>
        <label>Type:</label>
        <select
          v-model="machineForm.type_id"
          @change="fetchCustomFieldsForType"
        >
          <option v-for="type in types" :key="type.id" :value="type.id">
            {{ type.name }}
          </option>
        </select>
      </div>
      <div v-for="field in customFields" :key="field.id">
        <label>{{ field.name }}:</label>
        <input v-model="customFieldValues[field.id]" maxlength="254" />
      </div>
      <div class="bottom-button">
        <button class="primary-button" @click="saveMachine" :disabled="wait">
          <img
            v-if="wait"
            src="@/assets/spinner.gif"
            alt="Loading..."
            class="spinner"
          />
          {{ selectedMachine ? "Mettre à jour" : "Ajouter" }}
        </button>
        <button class="secondary-button" @click="closeModal">Fermer</button>
      </div>
    </modal>
    <button class="primary-button" @click="openModal(null)">
      Ajouter une nouvelle machine
    </button>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
import { showToast } from "@/sweetAlertService";

export default {
  name: "MachineManager",
  components: { Modal },
  data() {
    return {
      showModal: false,
      selectedMachine: null,
      machineForm: {
        name: "",
        description: "",
        type_id: null,
        client_id: null,
      },
      customFieldValues: {},
      machines: [],
      customFields: [],
      types: [],
      clients: [],
      sortKey: "client", // Default sorting key is 'client'
      filters: {
        name: "",
        client: "",
        type: "",
      },
      wait: false,
    };
  },
  computed: {
    ...mapGetters({
      getMachines: "getMachines",
      getCustomFields: "getCustomFields",
      getTypes: "getTypes",
      getClients: "getClients",
    }),
    filteredAndSortedMachines() {
      let filteredMachines = this.machines;

      if (this.filters.name) {
        filteredMachines = filteredMachines.filter((machine) =>
          machine.name.toLowerCase().includes(this.filters.name.toLowerCase())
        );
      }

      if (this.filters.client) {
        filteredMachines = filteredMachines.filter(
          (machine) => machine.client_id === this.filters.client
        );
      }

      if (this.filters.type) {
        filteredMachines = filteredMachines.filter(
          (machine) => machine.type_id === this.filters.type
        );
      }

      return filteredMachines.sort((a, b) => {
        if (this.sortKey === "name") {
          return a.name.localeCompare(b.name);
        } else if (this.sortKey === "client") {
          const clientA =
            this.clients.find((client) => client.id === a.client_id)?.name ||
            "";
          const clientB =
            this.clients.find((client) => client.id === b.client_id)?.name ||
            "";
          return clientA.localeCompare(clientB);
        } else if (this.sortKey === "type") {
          const typeA =
            this.types.find((type) => type.id === a.type_id)?.name || "";
          const typeB =
            this.types.find((type) => type.id === b.type_id)?.name || "";
          return typeA.localeCompare(typeB);
        }
        return 0;
      });
    },
  },
  methods: {
    ...mapActions([
      "createMachine",
      "updateMachine",
      "deleteMachine",
      "getAllMachines",
      "createOrUpdateCustomFieldValues",
      "getCustomFieldValues",
    ]),
    getClientName(clientId) {
      const client = this.clients.find((client) => client.id === clientId);
      return client ? client.name : "N/A";
    },
    getTypeName(typeId) {
      const type = this.types.find((type) => type.id === typeId);
      return type ? type.name : "N/A";
    },
    async selectMachine(machine) {
      this.selectedMachine = machine;
      this.machineForm.name = machine.name;
      this.machineForm.description = machine.description;
      this.machineForm.type_id = machine.type_id;
      this.machineForm.client_id = machine.client_id;

      await this.fetchCustomFieldsForType();
      this.showModal = true;
    },
    async openModal(machine) {
      this.selectedMachine = machine;
      this.types = this.getTypes;

      if (machine) {
        await this.selectMachine(machine);
      } else {
        this.machineForm = {
          name: "",
          description: "",
          type_id: null,
          client_id: null,
        };
        this.customFieldValues = {};
        this.showModal = true;
      }
    },
    async fetchCustomFieldsForType() {
      if (this.machineForm.type_id) {
        this.customFields = this.getCustomFields.filter(
          (field) => field.type_id === this.machineForm.type_id
        );

        // Initialize custom field values
        for (const field of this.customFields) {
          if (!this.customFieldValues[field.id]) {
            this.customFieldValues[field.id] = "";
          }
        }

        let customValues = [];
        if (this.selectedMachine) {
          // Récupérer les valeurs des champs personnalisés pour cette machine
          customValues = await this.getCustomFieldValues(
            this.selectedMachine.id
          );
          customValues = customValues.filter(
            (value) => value.machine_id === this.selectedMachine.id
          );
        }
        this.customFieldValues = {};
        for (const field of this.customFields) {
          const existingValue = customValues.find(
            (value) => value.custom_field_id === field.id
          );
          this.customFieldValues[field.id] = existingValue
            ? existingValue.value
            : "";
        }
      }
    },
    async saveMachine() {
      this.wait = true;
      if (
        !this.machineForm.name ||
        !this.machineForm.client_id ||
        !this.machineForm.type_id
      ) {
        showToast("error", "Veuillez remplir tous les champs");
        this.wait = false;
        return;
      }

      // Vérification des champs personnalisés
      for (const fieldId in this.customFieldValues) {
        if (!this.customFieldValues[fieldId]) {
          showToast("error", "Veuillez remplir tous les champs personnalisés");
          this.wait = false;
          return;
        }
      }

      let machineId = null;
      if (this.selectedMachine) {
        await this.updateMachine({
          id: this.selectedMachine.id,
          data: {
            name: this.machineForm.name,
            description: this.machineForm.description
              ? this.machineForm.description
              : "",
            client_id: this.machineForm.client_id,
            type_id: this.machineForm.type_id,
          },
        });
        machineId = this.selectedMachine.id;
      } else {
        const newMachine = {
          name: this.machineForm.name,
          description: this.machineForm.description,
          client_id: this.machineForm.client_id,
          type_id: this.machineForm.type_id,
        };
        const createdMachine = await this.createMachine(newMachine);
        machineId = createdMachine.id;
      }

      // Sauvegarde des valeurs des champs personnalisés
      for (const fieldId in this.customFieldValues) {
        await this.createOrUpdateCustomFieldValues({
          data: {
            custom_field_id: fieldId,
            value: this.customFieldValues[fieldId],
          },
          machine_id: machineId,
        });
      }

      await this.getAllMachines();
      this.machines = this.getMachines;
      this.wait = false;
      this.filterMachines();
      if (!this.selectedMachine) {
        this.machineForm = {
          name: "",
          description: "",
          type_id: this.machineForm.type_id,
          client_id: this.machineForm.client_id,
        };
      }
      this.closeModal();
    },
    closeModal() {
      this.showModal = false;
      this.selectedMachine = null;
      this.machineForm = {
        name: "",
        description: "",
        type_id: null,
        client_id: null,
      };
      this.customFieldValues = {};
    },
    async confirmDelete(machineId) {
      await this.deleteMachine(machineId);
      await this.getAllMachines();
      this.machines = this.getMachines;
      this.filterMachines();
    },
    filterMachines() {
      // This method is here to trigger the computed property re-evaluation
    },
    sortMachines(key) {
      this.sortKey = key;
      this.filterMachines();
    },
  },
  mounted() {
    this.machines = this.getMachines;
    this.types = this.getTypes;
    this.clients = this.getClients;
    this.filterMachines();
  },
  watch: {
    "machineForm.type_id": "fetchCustomFieldsForType",
  },
};
</script>

<style scoped>
.machine-manager {
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0 0 20px;
}

.filter-options {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.filter-data {
  width: 25%;
}

.list-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 0.5rem;
  border-bottom: 1px solid var(--color-border);
}

.header-item {
  flex: 1;
}

.header-item {
  cursor: pointer;
}

.header-item:last-child {
  flex: 0;
  text-align: right;
}

.machine-list {
  list-style-type: none;
  padding: 0;
  max-height: 500px;
  overflow: auto;
}

.machine-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.6rem 0;
  padding: 0.5rem;
  border: 1px solid var(--color-border);
  border-radius: 2px;
  cursor: pointer;
}

.machine-item {
  flex: 1;
}

.machine-item:last-child {
  flex: 0;
  text-align: right;
}

.delete-button {
  background-color: red;
  color: white;
  border: none;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
}

.delete-button:hover {
  background-color: darkred;
}

input,
select {
  margin-bottom: 10px;
  background-color: white;
  border-radius: 2px;
  padding: 10px;
  border: 1px solid var(--color-border);
  width: 94.5%;
}

input:focus,
select:focus {
  outline: none !important;
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 2px var(--color-primary);
}

.bottom-button {
  display: flex;
  justify-content: space-between;
}

.spinner {
  width: 12px;
  height: 12px;
}

@media (max-width: 1180px) {
  .machine-manager {
    margin: 0;
  }

  .filter-options {
    flex-direction: column;
    gap: 10px;
    margin-top: 0;
  }

  #name-filter {
    width: 90%;
  }

  .filter-options {
    flex-direction: column;
  }

  .filter-data {
    width: 100%;
    max-width: none;
  }

  .machine-list li {
    min-width: 600px;
  }
}
</style>
