<template>
  <div class="left-panel">
    <div class="content">
      <div class="header-left-panel">
        <div class="filter-options">
          <div class="filter-data">
            <label for="filter-title">Filtrer par titre du ticket:</label>
            <input id="filter-title" type="text" v-model="searchQuery" />
          </div>
          <div class="filter-data">
            <label for="client-filter">Filtrer par client:</label>
            <multiselect
              :noResults="customNoResults"
              id="client-filter"
              v-model="clientSelected"
              :options="getClientsNameList"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
            ></multiselect>
          </div>
          <div class="filter-data">
            <label for="status-filter">Filtrer par statut des ticket:</label>
            <multiselect
              :noResults="customNoResults"
              id="status-filter"
              v-model="selectedStatus"
              :options="getStatus"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              track-by="name"
              label="name"
              :allow-empty="false"
            ></multiselect>
          </div>
        </div>
        <button
          class="primary-button spe"
          @click="$emit('requestNewTicket', 'Créer')"
        >
          Nouveau ticket
        </button>
      </div>
      <div class="ticket-container">
        <h2>Tous les tickets {{ this.selectedStatus.name }}</h2>
        <div class="ticket-list">
          <li
            v-for="ticket in filteredTicketsOpen"
            :key="ticket.id"
            :class="{ selected: getSelectedTicket === ticket.id }"
            @click="selectTicket(ticket.id)"
          >
            <simplified-ticket
              :ticket-id="ticket.id"
              @ticket-updated="refreshList"
            />
          </li>
        </div>
        <h2>Mes tickets {{ this.selectedStatus.name }}</h2>
        <div class="ticket-list">
          <li
            v-for="ticket in filteredTicketsAssigned"
            :key="ticket.id"
            :class="{ selected: getSelectedTicket === ticket.id }"
            @click="selectTicket(ticket.id)"
          >
            <simplified-ticket
              :ticket-id="ticket.id"
              @ticket-updated="refreshList"
            />
          </li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import simplifiedTicket from "@/components/SimplifiedTicket.vue";
import { mapGetters, mapMutations } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "LeftPanel",
  components: {
    simplifiedTicket,
    Multiselect,
  },
  emits: ["requestNewTicket"],
  data() {
    return {
      ticketAssigned: [],
      ticketOpen: [],
      clientSelected: "Tous",
      searchQuery: "",
      selectedStatus: "",
    };
  },
  computed: {
    ...mapGetters({
      getUser: "getUser",
      getSelectedTicket: "getSelectedTicket",
      getTickets: "getTickets",
      getClients: "getClients",
      getStatus: "getStatus",
    }),
    getClientsNameList() {
      let clientList = ["Tous"];
      this.getClients.forEach((client) => {
        clientList.push(client.name);
      });
      return clientList;
    },
    filteredTicketsAssigned() {
      return this.ticketAssigned.filter(
        (ticket) =>
          ticket.title.toLowerCase().includes(this.searchQuery.toLowerCase()) &&
          ticket.state_id === this.selectedStatus.id
      );
    },
    filteredTicketsOpen() {
      return this.ticketOpen.filter(
        (ticket) =>
          ticket.title.toLowerCase().includes(this.searchQuery.toLowerCase()) &&
          ticket.state_id === this.selectedStatus.id
      );
    },
  },
  methods: {
    ...mapMutations(["setSelectedTicket"]),
    customNoResults() {
      return "Aucun élément trouvé";
    },
    // Sélectionne un ticket spécifique
    selectTicket(id) {
      this.setSelectedTicket(id);
    },
    // Filtre les tickets en fonction du client sélectionné
    FiltreOnlyClient() {
      if (this.clientSelected === "Tous") {
        this.filterTickets();
      } else {
        this.ticketAssigned = this.getTickets.filter(
          (ticket) =>
            ticket.technician_id.name === this.getUser.user.email &&
            ticket.client_id.name === this.clientSelected &&
            ticket.state_id !== 3
        );

        // récupération de tous les tickets ouverts non assignés à l'utilisateur connecté
        this.ticketOpen = this.getTickets.filter(
          (ticket) =>
            ticket.technician_id.name !== this.getUser.user.email &&
            ticket.client_id.name === this.clientSelected
        );
      }
    },
    // Filtre tous les tickets pour les assigné à différente liste
    filterTickets() {
      // récupération de tous les tickets assignés à l'utilisateur connecté
      this.ticketAssigned = this.getTickets.filter(
        (ticket) => ticket.technician_id.name === this.getUser.user.email
      );

      // récupération de tous les tickets ouverts non assignés à l'utilisateur connecté
      this.ticketOpen = this.getTickets.filter(
        (ticket) => ticket.technician_id.name !== this.getUser.user.email
      );
    },
    // Mise à jours d'un ticket spécifique dans les listes
    refreshList() {
      this.filterTickets();
      this.FiltreOnlyClient();
    },
  },
  watch: {
    getTickets() {
      this.filterTickets();
    },
    clientSelected() {
      if (this.clientSelected === null) {
        this.clientSelected = "Tous";
      }
      this.FiltreOnlyClient();
    },
  },
  created() {
    this.selectedStatus = this.getStatus[0];
  },
  mounted() {
    this.filterTickets();
  },
};
</script>

<style>
@media (max-width: 1180px) {
  .left-panel {
    width: 100% !important;
  }
}

.left-panel {
  margin-top: 2em;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0;
}

.content {
  padding: 1rem;
}

.header-left-panel {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  padding-right: 10px;
}

.ticket-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.ticket-list li {
  margin: 0.6rem;
  padding: 0.5rem;
  border: 1px solid var(--color-border);
  border-radius: 2px;
  cursor: pointer;
  max-width: 300px;
}

.selected {
  border: 2px solid var(--color-primary) !important;
}

.filter-options {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
  margin-right: 20px;
  gap: 50px;
}

.filter-data {
  flex: 1;
  margin-right: 10px;
  max-width: 300px;
}

.filter-data:last-child {
  margin-right: 0;
}

input,
select {
  background-color: white;
  border-radius: 2px;
  padding: 10px;
  border: 1px solid var(--color-border);
  width: 100%;
}

input:focus,
select:focus {
  outline: none !important;
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 2px var(--color-primary);
}

.spe {
  margin-top: 0px !important;
}

@media (max-width: 1180px) {
  .filter-options {
    flex-direction: column;
    gap: 10px;
    margin-top: 130px;
  }

  .left-panel {
    width: 100% !important;
  }

  #filter-title {
    width: 95%;
  }

  .filter-options {
    flex-direction: column;
  }

  .filter-data {
    width: 100%;
    max-width: none;
  }

  .spe {
    position: absolute;
    top: 300px;
    left: 16px;
  }

  .ticket-container {
    margin-top: 200px;
  }
}
</style>
