<template>
  <PasswordResetRequest v-if="!reset" />
  <PasswordReset v-else />
</template>

<script>
import PasswordResetRequest from "@/components/PasswordResetRequest.vue";
import PasswordReset from "@/components/PasswordReset.vue";

// Si la route est /password/reset, affiche le composant PasswordResetRequest

export default {
  name: "PasswordView",
  components: {
    PasswordResetRequest,
    PasswordReset,
  },
  data() {
    return {
      reset: false,
    };
  },
  created() {
    if (this.$route.params.token) {
      this.reset = true;
    }
  },
};
</script>

<style scoped></style>
