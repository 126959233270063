<template>
  <div class="login-view">
    <div class="form-container">
      <img
        class="logo"
        alt="SteVal Consulting logo"
        :src="require('@/assets/Steval-logo-info.png')"
      />
      <input
        class="input"
        v-model="email"
        type="email"
        placeholder="E-mail"
        autofocus
        @keyup.enter="this.loginHandler"
      />
      <div class="input-group">
        <input
          class="input"
          id="input-password"
          v-model="password"
          :type="inputType"
          placeholder="Mot de passe"
          @keyup.enter="this.loginHandler"
        />
        <button class="input-icon" @click="togglePassword">
          {{ toggleBtnIcon }}
        </button>
      </div>
      <button class="primary-button" @click="loginHandler">
        <span v-if="isLoading">
          <img src="@/assets/spinner.gif" alt="loading" class="spinner-icon" />
          Chargement...
        </span>
        <span v-else>Connexion</span>
      </button>
      <router-link to="/password" class="forgot-password-link">
        Mot de passe oublié ?
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LoginView",
  data() {
    return {
      showPassword: false,
      email: "",
      password: "",
      isLoading: false,
    };
  },
  computed: {
    inputType() {
      return this.showPassword ? "text" : "password";
    },
    toggleBtnIcon() {
      return this.showPassword ? "👁️‍🗨️" : "👁️‍🗨️";
    },
  },
  methods: {
    ...mapActions(["login"]),
    // Lance la connexion de l'utilisateur
    loginHandler() {
      this.isLoading = true;
      this.login({ email: this.email, password: this.password })
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    // Gère l'affichage du mot de passe
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped>
@media (max-width: 1180px) {
  .logo {
    width: 100%;
  }
}

.login-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 12vh;
}

.input {
  margin: 5px 0 5px 0;
  padding: 10px;
  width: 30vh;
  border-radius: 0.2em;
  border: 1px solid var(--color-primary);
}

.input-icon {
  padding: 0.5rem;
  display: inline;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: 0;
  background-color: inherit;
  cursor: pointer;
  width: fit-content;
  margin-left: -2rem;
}

.input-group {
  display: flex;
  align-items: center;
}

.spinner-icon {
  height: 1em;
  width: 1em;
  margin-right: 0.5em;
}

input:focus {
  outline: none !important;
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 5px var(--color-primary);
}

.forgot-password-link {
  margin-top: 10px;
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.8em;
}

.forgot-password-link:hover {
  text-decoration: underline;
  font-weight: bold;
}
</style>
