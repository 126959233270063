<template>
  <div class="priority-manager">
    <h2>Priorités</h2>
    <div class="list-header">
      <span class="header-item">Nom de la priorité</span>
      <span class="header-item">Couleur</span>
      <span class="header-item">Actions</span>
    </div>
    <ul class="priority-list">
      <li v-for="prio in priority" :key="prio.id" @click="selectPriority(prio)">
        <span class="priority-item">{{ prio.name }}</span>
        <span class="priority-item">
          <span
            class="priority-color"
            :style="{ backgroundColor: prio.color }"
          ></span>
        </span>
        <span class="priority-item">
          <button class="delete-button" @click.stop="confirmDelete(prio.id)">
            Supprimer
          </button>
        </span>
      </li>
    </ul>
    <button class="primary-button" @click="openModal(null)">
      Créer un nouveau type
    </button>
    <modal v-if="showModal" @close="closeModal">
      <h3>
        {{
          selectedPriority
            ? "Modifier la priorité"
            : "Créer une nouvelle priorité"
        }}
      </h3>
      <div>
        <label>Nom de la priorité:</label>
        <input v-model="priorityForm.name" maxlength="254" />
      </div>
      <div>
        <label>Couleur de la priorité:</label>
        <input
          maxlength="250"
          type="color"
          v-bind:value="priorityForm.color"
          @input="updateColor($event)"
        />
      </div>
      <div class="bottom-button">
        <button class="primary-button" @click="savePriority" :disabled="wait">
          <img
            v-if="wait"
            src="@/assets/spinner.gif"
            alt="Loading..."
            class="spinner"
          />
          {{ selectedPriority ? "Mettre à jour" : "Créer" }}
        </button>
        <button class="secondary-button" @click="closeModal">Fermer</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
import { showToast } from "@/sweetAlertService";

export default {
  name: "PriorityManager",
  components: { Modal },
  data() {
    return {
      showModal: false,
      selectedPriority: null,
      priorityForm: {
        name: "",
        color: "#ffffff", // Default color
      },
      priority: [],
      wait: false,
    };
  },
  computed: {
    ...mapGetters({
      getPriority: "getPriority",
      getTickets: "getTickets",
    }),
  },
  methods: {
    ...mapActions([
      "createPriority",
      "updatePriority",
      "deletePriority",
      "getAllPriorities",
    ]),
    async selectPriority(prio) {
      this.selectedPriority = prio;
      this.priorityForm.name = prio.name;
      this.priorityForm.color = prio.color;
      this.showModal = true;
    },
    updateColor(event) {
      this.priorityForm.color = event.target.value;
    },
    async savePriority() {
      this.wait = true;
      // Vérifie que les champs sont remplis
      if (!this.priorityForm.name || !this.priorityForm.color) {
        showToast("error", "Veuillez remplir tous les champs");
        this.wait = false;
        return;
      }

      if (this.selectedPriority) {
        const updatedPrio = {
          data: {
            name: this.priorityForm.name,
            color: this.priorityForm.color,
          },
          id: this.selectedPriority.id,
        };
        await this.updatePriority(updatedPrio);
      } else {
        // Création du type
        const newPrio = {
          name: this.priorityForm.name,
          color: this.priorityForm.color,
        };
        await this.createPriority(newPrio);
      }

      await this.getAllPriorities();
      this.priority = this.getPriority;
      this.wait = false;
      this.closeModal();
    },
    openModal(state) {
      this.selectedPriority = state;
      this.priorityForm = state
        ? { name: state.name, color: state.color }
        : { name: "", color: "#ffffff" };
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedPriority = null;

      // Reset the form
      this.priorityForm = {
        name: "",
        color: "#ffffff",
      };
    },
    async confirmDelete(prioId) {
      // il ne peux pas y avoir 0 status
      const status = this.getPriority;

      // Si un ticket utilise le status error
      const ticketWithPrio = this.getTickets.find(
        (ticket) => ticket.priority_id === prioId
      );
      if (ticketWithPrio) {
        showToast(
          "error",
          "Vous ne pouvez pas supprimer cette priorité car il est utilisé par un ticket."
        );
        return;
      }

      if (status.length === 1) {
        showToast(
          "error",
          "Vous ne pouvez pas supprimer la dernière priorité. Vous devez en avoir au moins un."
        );
        return;
      }
      await this.deletePriority(prioId);
      await this.getAllPriorities();
      this.priority = this.getPriority;
    },
  },
  async mounted() {
    await this.getAllPriorities();
    this.priority = this.getPriority;
  },
};
</script>

<style scoped>
.priority-manager {
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0 0 20px;
}

.list-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 0.5rem;
  border-bottom: 1px solid var(--color-border);
}

.header-item {
  flex: 1;
}

.header-item:last-child {
  flex: 0;
  text-align: right;
}

.priority-list {
  list-style-type: none;
  padding: 0;
  max-height: 600px;
  overflow-y: auto;
}

.priority-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.6rem 0;
  padding: 0.5rem;
  border: 1px solid var(--color-border);
  border-radius: 2px;
  cursor: pointer;
}

.priority-item {
  flex: 1;
  overflow-y: hidden;
}

.priority-item:last-child {
  flex: 0;
  text-align: right;
  overflow-y: visible;
}

.delete-button {
  background-color: red;
  color: white;
  border: none;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
}

.delete-button:hover {
  background-color: darkred;
}

input,
select {
  margin-bottom: 10px;
  background-color: white;
  border-radius: 2px;
  padding: 10px;
  border: 1px solid var(--color-border);
  width: 94.5%;
}

input[type="color"] {
  width: 100%;
  padding: 0;
  height: 40px;
  border: none;
}

input:focus,
select:focus {
  outline: none !important;
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 2px var(--color-primary);
}

.bottom-button {
  display: flex;
  justify-content: space-between;
}

.spinner {
  width: 12px;
  height: 12px;
}

.priority-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
</style>
