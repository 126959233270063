<template>
  <div id="app">
    <header>
      <img
        v-show="!isMobile"
        class="logo"
        alt="SteVal Consulting logo"
        :src="require('@/assets/Steval-logo-info.png')"
      />
      <nav class="navbar">
        <router-link class="nav-link" to="/">Tableau de bord</router-link>
        <router-link class="nav-link" to="/clients">Clients</router-link>
        <router-link class="nav-link" to="/me">Profil</router-link>
        <!-- affice ce lien seulement si le technicien est admin -->
        <router-link class="nav-link" to="/admin" v-if="isAdmin">
          Admin
        </router-link>

        <p @click="logoutHandler" class="nav-link">Déconnexion</p>
      </nav>
    </header>
    <router-view v-if="!loading" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Pusher from "pusher-js";

export default {
  name: "AppView",
  data() {
    return {
      loading: true,
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters({
      getTickets: "getTickets",
      getClients: "getClients",
      getTechnicians: "getTechnicians",
      getUser: "getUser",
    }),
    isMobile() {
      return this.screenWidth < 1200;
    },
    isAdmin() {
      return this.getUser.user.roles_id === 3;
    },
  },
  methods: {
    ...mapActions({
      logout: "logout",
      getAllClients: "getAllClients",
      getAllTechnicians: "getAllTechnicians",
      getAllTickets: "getAllTickets",
      getOneTicket: "getOneTicket",
      getAllPriorities: "getAllPriorities",
      getAllStatus: "getAllStatus",
      refreshToken: "refreshToken",
      getAllMachines: "getAllMachines",
      getAllTypes: "getAllTypes",
      getAllCustomFields: "getAllCustomFields",
      getAllConcerns: "getAllConcerns",
    }),
    ...mapMutations({
      setTickets: "setTickets",
      setOneTicket: "setOneTicket",
      setSelectedTicket: "setSelectedTicket",
    }),
    // Lance la connexion de l'utilisateur
    logoutHandler() {
      this.logout();
    },
    // Récupère les données que l'interface graphique à besoin
    async getDataHandler() {
      this.loading = true;
      try {
        await Promise.all([
          this.getAllClients(),
          this.getAllTechnicians(),
          this.getAllTickets(),
          this.getAllPriorities(),
          this.getAllStatus(),
          this.getAllMachines(),
          this.getAllTypes(),
          this.getAllCustomFields(),
          this.getAllConcerns(),
        ]);

        // pour tous les techniciens, on ajoute un champ "name"
        this.getTechnicians.forEach((tech) => {
          tech.name = tech.email;
        });

        this.parseData();
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },
    // Modifi les données reçues pour les rendre plus facile à utiliser
    async parseData() {
      this.parseTech();

      // Dans la table clients, fait en sorte que le champ id devienne le principal
      // Ceci est utile pour la flexibilité de mes composants.
      this.getClients.forEach((client) => {
        client.id = client.winbiz_id;
        delete client.winbiz_id;
      });

      this.getTickets.forEach((ticket) => {
        this.parseTicket(ticket);
      });
    },
    parseTech() {
      // Crée le technician Aucun technicien
      const technician = this.getTechnicians;

      // Si le Technicien existe déjà, on le supprime
      technician.forEach((tech, index) => {
        if (tech.name === "Aucun technicien") {
          technician.splice(index, 1);
        }
      });
      // Crée le technicien Aucun
      technician.unshift({ id: 0, name: "Aucun technicien" });

      // Nom des role
      technician.forEach((tech) => {
        tech.roles_id = {
          id: tech.roles_id,
          name:
            tech.roles_id === 3
              ? "Admin"
              : tech.roles_id === 2
              ? "Technicien"
              : "Désactivé",
        };
      });
    },
    parseTicket(ticket) {
      // Si le technicien est null, on lui donne le technicien local Aucun technicien
      if (ticket.technician_id === null) {
        ticket.technician_id = 0;
      }

      ticket.technician_id = {
        id: ticket.technician_id,
        name: this.getTechnicians.find(
          (tech) => tech.id === ticket.technician_id
        ).name,
      };

      // Nom des clients
      ticket.client_id = {
        id: this.getClients.find((cli) => cli.id === ticket.client_id).id,
        name: this.getClients.find((cli) => cli.id === ticket.client_id).name,
      };

      // parse la date
      ticket.created_at =
        new Date(ticket.created_at).getUTCDate() +
        "." +
        (new Date(ticket.created_at).getUTCMonth() + 1) +
        "." +
        new Date(ticket.created_at).getUTCFullYear();
    },
    // Gère le redimensionnement de la fenêtre
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    async synchronizeTickets(data) {
      switch (data.state) {
        case "created": {
          try {
            await this.getAllConcerns();
            // Récupération du nouveau ticket
            let ticket = await this.getOneTicket(data.ticketId);
            // mise à jours de ces informations
            await this.parseTicket(ticket);
            // ajout de ce ticket dans la base local
            this.setTickets([...this.getTickets, ticket]);
          } catch (error) {
            console.error(error);
          }
          break;
        }
        case "updated": {
          try {
            await this.getAllConcerns();
            let ticket = await this.getOneTicket(data.ticketId);
            // mise à jours de ces informations
            await this.parseTicket(ticket);

            const index = this.getTickets.findIndex(
              (ticket) => ticket.id === data.ticketId
            );

            this.setOneTicket({ ticket, index });
          } catch (error) {
            console.error(error);
          }
          break;
        }
        case "deleted": {
          const allTicket = this.getTickets.filter(
            (ticket) => ticket.id !== data.ticketId
          );
          this.setTickets(allTicket);
          break;
        }
        case "technician": {
          try {
            await this.getAllTechnicians();
            // mise à jours de ces informations
            await this.parseTech();
          } catch (error) {
            console.error(error);
          }
          break;
        }
        case "machine": {
          try {
            await this.getAllMachines();
          } catch (error) {
            console.error(error);
          }
          break;
        }
        case "type": {
          try {
            await this.getAllTypes();
          } catch (error) {
            console.error(error);
          }
          break;
        }
      }
    },
  },
  watch: {
    getSelectedTicket() {
      if (this.getSelectedTicket) {
        this.usingRightPanelFor = undefined;
      }

      // Force la mise à jour de displayRightPanel
      this.$nextTick(() => {
        this.displayingRightPanel;
      });
    },
  },
  created() {
    const pusher = new Pusher("277f936bb85cae3f0c43", {
      cluster: "eu",
    });

    // Souscription à un canal public
    const channel = pusher.subscribe("ticket-updated");

    channel.bind("App\\Events\\TicketUpdated", (data) => {
      this.synchronizeTickets(data.data);
    });

    // Rafraichi le token toutes les 30 minutes
    setInterval(() => {
      this.refreshToken();
    }, 1800000);

    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.getDataHandler();
  },
};
</script>

<style scoped>
nav {
  margin-right: 2em;
  display: flex;
  overflow-x: auto;
}

header {
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 20);
}

.nav-link {
  display: inline-block;
  color: var(--color-secondary);
  text-decoration: none;
  margin: auto;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;
  cursor: pointer;
}

.nav-link:first-child {
  margin-left: 0;
}

.nav-link:last-child {
  margin-right: 0;
  display: inline;
}

.nav-link:hover,
.nav-link.router-link-exact-active {
  color: var(--color-text-light);
}
</style>
