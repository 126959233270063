<template>
  <div class="ticket-simplified">
    <div class="row-1">
      <div class="name">
        <p>{{ ticket.client_id.name }}</p>
      </div>
      <div class="priority" :style="priorityStyle()"></div>
      <div class="date">
        <p>{{ ticket.created_at }}</p>
      </div>
    </div>
    <div class="row-2">
      <p>{{ ticket.title }}</p>
    </div>
    <div class="row-3">
      <p>{{ ticket.technician_id.name }}</p>
    </div>
    <span v-if="ticket.title.length > 30" class="tooltip">{{
      ticket.title
    }}</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TicketSimplified",
  emits: ["ticketUpdated"],
  props: {
    ticketId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getTicketById: "getTicketById",
      getPriority: "getPriority",
    }),
    ticket() {
      return this.getTicketById(this.ticketId);
    },
    priority() {
      return this.getPriority.find(
        (priority) => priority.id === this.ticket.priority_id
      );
    },
  },
  methods: {
    priorityStyle() {
      return {
        backgroundColor: this.priority ? this.priority.color : "transparent",
      };
    },
  },
  watch: {
    ticket: {
      deep: true,
      handler(newVal, oldVal) {
        // Si le technicien à changé ou si
        // le statut du ticket à changé, on envoie un emit
        if (
          newVal.technician_id.id !== oldVal.technician_id.id ||
          newVal.state_id !== oldVal.state_id ||
          newVal.client_id.id !== oldVal.client_id.id
        ) {
          this.$emit("ticketUpdated");
        }
      },
    },
  },
};
</script>

<style scoped>
.ticket-simplified {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.ticket-simplified p {
  margin: 0;
  display: inline;
}

/* row */

.row-1 {
  display: flex;
  justify-content: space-between;
}

.row-2 {
  margin: 1rem 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
}

.row-3 {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

/* name */
.name {
  width: 12rem;
  max-width: 12rem;
}

.name p {
  font-weight: bold;
}

/* priority */

.priority {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid var(--color-primary);
  margin: 0 0.2rem 0 1rem;
}

/* Tooltip */
.tooltip {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
}

.ticket-simplified:hover .tooltip {
  visibility: visible;
}
</style>
