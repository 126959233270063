import Swal from "sweetalert2";

const toast = Swal.mixin({
  toast: true,
  position: "top-end",
  iconColor: "white",
  customClass: {
    popup: "colored-toast",
  },
  showConfirmButton: false,
  showCloseButton: true,
  timer: 2500,
  timerProgressBar: true,
});
//
export function showToast(icon, title) {
  toast.fire({
    icon: icon,
    title: title,
  });
}

export function showConfirmDeleteTicket() {
  return Swal.fire({
    title: "Supprimer le ticket ?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Oui, le supprimer",
    cancelButtonText: "Annuler",
  });
}
