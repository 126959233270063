<template>
  <div class="ticket-detail">
    <div>
      <h2 class="section-title">Informations</h2>
      <div class="list-container">
        <div class="select-list-container">
          <div class="data-list">
            <p class="title-list">Client :</p>
            <multiselect
              :noResults="customNoResults"
              v-if="clientList"
              v-model="client"
              :options="clientList"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :allow-empty="false"
              track-by="id"
              label="name"
              :disabled="ticketClosed"
            ></multiselect>
          </div>
          <div class="data-list">
            <p class="title-list">Technicien :</p>
            <multiselect
              :noResults="customNoResults"
              v-if="technicianList"
              v-model="technician"
              :options="technicianList"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :allow-empty="false"
              track-by="id"
              label="name"
              :disabled="ticketClosed"
            ></multiselect>
          </div>
          <div class="data-list">
            <p class="title-list">Priorité :</p>
            <multiselect
              :noResults="customNoResults"
              v-if="priorityList"
              v-model="priority"
              :options="priorityList"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :allow-empty="false"
              track-by="id"
              label="name"
              :disabled="ticketClosed"
            ></multiselect>
          </div>
          <div class="data-list">
            <p class="title-list">Statut :</p>
            <multiselect
              :noResults="customNoResults"
              v-if="stateList"
              v-model="status"
              :options="stateList"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :allow-empty="false"
              track-by="id"
              label="name"
            ></multiselect>
          </div>
        </div>
        <div>
          <button
            class="button"
            @click="showModal = true"
            :disabled="ticketClosed"
          >
            Modifier les machines
          </button>
          <div class="selected-machines-container">
            <span
              class="machine-tag"
              v-for="machine in selectedMachines"
              :key="machine.id"
            >
              {{ machine.name }}
              <span class="remove-tag" @click="removeMachine(machine.id)"
                >&times;</span
              >
            </span>
          </div>
          <MachineModal
            :machines="filteredMachineList"
            v-model:isVisible="showModal"
            :initialSelected="allMachineSelected"
            @machines-selected="machinesSelected"
            :disabled="ticketClosed"
          />
        </div>
      </div>
      <div>
        <p class="section-title">Titre :</p>
        <input
          type="text"
          v-model="title"
          maxlength="250"
          :disabled="ticketClosed"
        />
      </div>
      <div>
        <p class="section-title">Description :</p>
        <textarea
          v-model="summary"
          cols="60"
          maxlength="250"
          name="text-summary"
          rows="5"
          wrap="hard"
          :disabled="ticketClosed"
        />
      </div>
      <br />
    </div>
    <div v-if="ticketId">
      <h2 class="section-title">Actions</h2>
      <Actions ref="refActions" :ticket-id="ticketId" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { showToast } from "@/sweetAlertService";
import Actions from "@/components/Actions.vue";
import MachineModal from "@/components/MachineModal.vue";
import Multiselect from "vue-multiselect";

export default {
  name: "TicketDetail",
  components: {
    Multiselect,
    Actions,
    MachineModal,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      priorityList: "",
      stateList: "",
      machineList: [],
      allMachineSelected: [],
      selectedMachines: [],
      technicianList: "",
      clientList: "",
      client: null,
      technician: null,
      priority: null,
      status: null,
      title: "",
      summary: "",
      closed_at: null,
    };
  },
  computed: {
    ...mapGetters({
      getPriority: "getPriority",
      getStatus: "getStatus",
      getTechnicians: "getTechnicians",
      getClients: "getClients",
      getMachines: "getMachines",
      getConcerns: "getConcerns",
    }),
    ticketId() {
      return this.ticket.id;
    },
    filteredMachineList() {
      if (this.client === null) {
        return [];
      }

      return this.machineList.filter(
        (machine) => machine.client_id === this.client.id
      );
    },
    ticketClosed() {
      return this.ticket.state_id === 3;
    },
  },
  methods: {
    ...mapActions({
      updateTicket: "updateTicket",
      createTicket: "createTicket",
      updateAction: "updateAction",
      updateTicketMachine: "updateTicketMachine",
      updateConcern: "updateConcern",
    }),
    ...mapMutations({
      setSelectedTicket: "setSelectedTicket",
    }),
    customNoResults() {
      return "Aucun élément trouvé";
    },
    // Met à jour le client sélectionné
    clientSelected(data) {
      this.client = data.id;
      this.allMachineSelected = [];
      this.selectedMachines = [];
    },
    // Met à jour le technicien sélectionné
    technicianSelected(data) {
      this.technician = data.id === 0 ? null : data.id;
    },
    //Met à jour la machine sélectionnée
    machinesSelected(selectedIds) {
      this.allMachineSelected = selectedIds;
      this.selectedMachines = this.machineList.filter((machine) =>
        selectedIds.includes(machine.id)
      );
    },
    removeMachine(machineId) {
      this.allMachineSelected = this.allMachineSelected.filter(
        (id) => id !== machineId
      );
      this.selectedMachines = this.selectedMachines.filter(
        (machine) => machine.id !== machineId
      );
    },
    // Met à jour le ticket
    save() {
      let action = this.$refs.refActions.actions;

      // Trie les actions pour supprimer les actions ave le state deleted
      action = action.filter((act) => act.state !== "deleted");

      let error = false;
      // Vérifie que les champs des actions sont remplis
      if (action.length > 0) {
        action.forEach((act) => {
          if (
            act.duration.trim() === "" ||
            act.description.trim() === "" ||
            act.date === ""
          ) {
            showToast(
              "error",
              "Des champs requis pour les actions sont manquants."
            );
            error = true;
          }
        });
      }

      // Récupère les machines sélectionnées
      // Si selectedMAchines est vide créer un tableau vide
      let concerns = [];
      if (this.selectedMachines.length !== 0) {
        concerns = this.selectedMachines.map((machine) => {
          return {
            machine_id: machine.id,
          };
        });
      }

      let data = {
        id: this.ticket.id,
        data: {
          concerns,
        },
      };

      this.updateTicketMachine(data);
      // Récupère les actions avec les status deleted
      action = this.$refs.refActions.actions;

      // Si une erreur est détectée, on arrête la fonction
      if (error) {
        return;
      } else {
        this.updateAction(action);
      }

      // Vérifi que les champs sont remplis
      if (
        this.client === 0 ||
        this.summary.trim() === "" ||
        this.title.trim() === ""
      ) {
        showToast("error", "Des champs requis sont manquants.");
      } else {
        this.updateTicket({
          id: this.ticket.id,
          title: this.title,
          summary: this.summary,
          client_id: this.client.id,
          priority_id: this.priority.id,
          state_id: this.status.id,
          technician_id: this.technician.id === 0 ? null : this.technician.id,
          closed_at: this.closed_at,
        });

        // Met à jours les machines sélectionner avec les concerns
        const concern = {
          id: this.ticket.id,
          data: this.selectedMachines,
        };

        this.updateConcern(concern);
      }
    },
    // Crée un nouveau ticket
    create() {
      if (
        this.client === 0 ||
        this.summary.trim() === "" ||
        this.title.trim() === ""
      ) {
        showToast("error", "Des champs requis sont manquants.");
      } else {
        this.createTicket({
          title: this.title,
          summary: this.summary,
          client_id: this.client.id,
          priority_id: this.priority.id,
          state_id: this.status.id,
          technician_id: this.technician.id === 0 ? null : this.technician.id,
          closed_at: this.closed_at,
        });

        // Met à jours les machines sélectionner avec les concerns
        const concern = {
          id: this.ticket.id,
          data: this.selectedMachines,
        };

        this.updateConcern(concern);
      }
    },
    // Réinitialise les données
    resetData() {
      this.client = this.ticket.client_id;
      this.technician =
        this.ticket.technician_id.id === 0
          ? { id: 0, name: "Aucun technicien" }
          : this.ticket.technician_id;
      this.priority = this.getPriority.find(
        (priority) => priority.id === this.ticket.priority_id
      );
      this.status = this.getStatus.find(
        (status) => status.id === this.ticket.state_id
      );
      this.title = this.ticket.title;
      this.summary = this.ticket.summary;
      this.closed_at = this.ticket.closed_at;
    },
  },
  watch: {
    ticket: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.resetData();
        }

        this.allMachineSelected = [];
        this.selectedMachines = [];

        let concern = this.getConcerns;
        for (let i = 0; i < concern.length; i++) {
          if (concern[i].ticket_id === this.ticket.id) {
            this.allMachineSelected.push(concern[i].machine_id);
            this.selectedMachines.push(
              this.machineList.find(
                (machine) => machine.id === concern[i].machine_id
              )
            );
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.resetData();
    this.priorityList = this.getPriority;
    this.stateList = this.getStatus;
    this.machineList = this.getMachines;
    this.clientList = [...this.getClients];
    this.technicianList = this.getTechnicians;

    let concern = this.getConcerns;
    for (let i = 0; i < concern.length; i++) {
      if (concern[i].ticket_id === this.ticket.id) {
        this.allMachineSelected.push(concern[i].machine_id);
        this.selectedMachines.push(
          this.machineList.find(
            (machine) => machine.id === concern[i].machine_id
          )
        );
      }
    }
  },
};
</script>

<style scoped>
.ticket-detail {
  padding-right: 1rem;
  margin-bottom: 4rem;
}

.section-title {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid;
  padding-bottom: 0.1rem;
}

.list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.select-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.data-list {
  flex: 1;
  min-width: 200px; /* Adjust this value based on your layout */
}

.title-list {
  margin: 0 0 0.5rem;
}

textarea,
input {
  margin-bottom: 10px;
  resize: vertical;
  background-color: white;
  border-radius: 2px;
  padding: 10px;
  border: 1px solid var(--color-border);
  width: 94.5%;
}

textarea:focus,
input:focus {
  outline: none !important;
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 2px var(--color-primary);
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0;
  width: 12em;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 2px;
  border: 1px solid var(--color-border);
  border-radius: 3px;
  background-color: white;
}

.button:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.selected-machines-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}

.machine-tag {
  display: inline-block;
  padding: 2px 8px;
  background-color: var(--color-primary);
  color: white;
  border-radius: 12px;
  font-size: 0.8em;
}

.remove-tag {
  margin-left: 8px;
  cursor: pointer;
  color: red;
}
</style>
