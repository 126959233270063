<template>
  <div v-if="isReady">
    <div class="modal-backdrop" @click="cancel"></div>
    <div class="right-panel right-panel-modal">
      <div class="content">
        <div class="button-container">
          <button class="secondary-button-2" @click="saveTicket">
            {{ usingFor }}
          </button>
          <button
            class="secondary-button"
            v-show="usingFor !== 'Créer'"
            @click="removeTicket"
          >
            Supprimer
          </button>
          <button class="secondary-button" @click="cancel">Fermer</button>
        </div>
        <DetailedTicket :ticket="ticket" ref="detailedComponent" />
      </div>
    </div>
  </div>
</template>

<script>
import DetailedTicket from "@/components/DetailedTicket.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { showConfirmDeleteTicket } from "@/sweetAlertService";

export default {
  name: "ModalRightPanel",
  components: {
    DetailedTicket,
  },
  emits: ["close-modal"],
  props: {
    usingFor: {
      type: String,
      default: "Enregistrer",
    },
  },
  data() {
    return {
      ticket: {},
      isReady: false,
    };
  },
  computed: {
    ...mapGetters({
      getSelectedTicket: "getSelectedTicket",
      getTickets: "getTickets",
      getClients: "getClients",
      getTechnicians: "getTechnicians",
      getStatus: "getStatus",
      getPriority: "getPriority",
    }),
  },
  methods: {
    ...mapMutations({
      setSelectedTicket: "setSelectedTicket",
    }),
    ...mapActions({
      deleteTicket: "deleteTicket",
    }),
    getTicket() {
      if (this.usingFor === "Créer") {
        this.ticket = {
          title: "",
          description: "",
          client_id: {
            id: 0,
            name: "Aucun client",
          },
          summary: "",
          priority_id: this.getPriority[this.getPriority.length - 1].id,
          state_id: this.getStatus[0].id,
          technician_id: {
            id: this.getTechnicians[0].id,
            name: this.getTechnicians[0].name,
          },
          actions: [],
        };
      } else {
        this.ticket = this.getTickets.find(
          (ticket) => ticket.id === this.getSelectedTicket
        );
      }
      this.isReady = true;
    },
    cancel() {
      this.setSelectedTicket(undefined);
      this.isReady = false;
      this.$emit("close-modal");
    },
    removeTicket() {
      showConfirmDeleteTicket(this.getSelectedTicket).then((result) => {
        if (result.isConfirmed) {
          this.deleteTicket(this.getSelectedTicket);
          this.cancel();
        }
      });
    },
    saveTicket() {
      if (this.usingFor === "Créer") {
        if (this.$refs.detailedComponent) {
          this.$refs.detailedComponent.create();
        }
        this.$emit("close-modal");
      } else {
        if (this.$refs.detailedComponent) {
          this.$refs.detailedComponent.save();
        }
      }
    },
  },
  watch: {
    getSelectedTicket() {
      if (this.getSelectedTicket !== undefined) {
        this.getTicket();
      }
    },
    usingFor() {
      this.getTicket();
    },
  },
  mounted() {
    this.getTicket();
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.right-panel-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-width: 800px;
  height: auto;
  border-left: none;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-height: 600px;
  overflow-y: auto;
}

.content {
  padding: 1rem;
}

.button-container {
  position: sticky;
  top: 0rem;
  right: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  max-height: 100%;
  z-index: 200;
  background-color: white;
}

.secondary-button-2 {
  margin-top: 10px;
  padding: 10px;
  width: 15vh;
  border: 1px solid var(--color-border-dark);
  border-radius: 0.2em;
  background-color: var(--color-background);
  color: var(--color-text);
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.secondary-button-2:hover {
  background-color: #eaeafa;
}
</style>
