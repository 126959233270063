<template>
  <div class="technician-container">
    <div class="technician-form">
      <h2>
        {{
          technicianSelected ? "Modifier le Technicien" : "Créer un Technicien"
        }}
      </h2>
      <div>
        <label for="email">Email:</label>
        <input
          type="text"
          v-model="technicianForm.email"
          id="email"
          maxlength="250"
        />
      </div>
      <div>
        <label for="role">Rôle:</label>
        <select v-model="technicianForm.role" id="role">
          <option v-for="role in roles" :key="role.id" :value="role">
            {{ role.name }}
          </option>
        </select>
      </div>
      <div v-if="!technicianSelected">
        <label for="password">Mot de passe:</label>
        <input
          type="password"
          v-model="technicianForm.password"
          id="password"
        />
      </div>
      <div v-if="!technicianSelected">
        <label for="confirmPassword">Confirmer le mot de passe:</label>
        <input
          type="password"
          v-model="technicianForm.confirmPassword"
          id="confirmPassword"
        />
      </div>
      <div class="form-buttons">
        <button @click="technicianSelected ? update() : create()">
          {{ technicianSelected ? "Mettre à jour" : "Créer" }}
        </button>
        <button v-if="technicianSelected" @click="resetForm">
          Créer un nouveau technicien
        </button>
      </div>
    </div>
    <div class="technician-list-container">
      <div class="technician-list-header">
        <div class="technician-row">
          <div class="technician-cell header">Email</div>
          <div class="technician-cell header">Rôle</div>
        </div>
      </div>
      <div class="technician-list-content">
        <div
          class="technician-row"
          v-for="technician in filteredTechnicians"
          :key="technician.id"
          :class="{
            selected:
              technicianSelected && technicianSelected.id === technician.id,
          }"
          @click="selectTechnician(technician)"
        >
          <div class="technician-cell">{{ technician.email }}</div>
          <div class="technician-cell">{{ technician.roles_id.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { showToast } from "@/sweetAlertService";

export default {
  name: "technicianView",
  data() {
    return {
      technicianSelected: null,
      technicianForm: {
        email: "",
        role: { id: 2, name: "Technicien" },
        password: "",
        confirmPassword: "",
      },
      roles: [
        { id: 3, name: "Admin" },
        { id: 2, name: "Technicien" },
        { id: 1, name: "Désactivé" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getTechnicians: "getTechnicians",
    }),
    filteredTechnicians() {
      return this.getTechnicians.filter(
        (technician) => technician.name !== "Aucun technicien"
      );
    },
  },
  methods: {
    ...mapActions(["createTechnician", "updateTechnician"]),
    create() {
      // Vérifie que les mots de passe corresponde
      if (
        this.technicianForm.password !== this.technicianForm.confirmPassword
      ) {
        showToast("error", "Les mots de passe ne correspondent pas");
        return;
      }

      // Vérifi que les mots de passe sont supérieur à 4 caractères
      if (this.technicianForm.password.length < 4) {
        showToast(
          "error",
          "Le mot de passe doit contenir au moins 4 caractères"
        );
        return;
      }

      // Vérifi que le champ contient bien un email
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.technicianForm.email)) {
        showToast("error", "Veuillez entrer un email valide");
        return;
      }

      // Vérifie que aucun technicien n'a le même email
      if (
        this.getTechnicians.some(
          (technician) => technician.email === this.technicianForm.email
        )
      ) {
        showToast("error", "Un technicien avec cet email existe déjà");
        return;
      }

      const newTechnician = {
        email: this.technicianForm.email,
        roles_id: this.technicianForm.role.id,
        password: this.technicianForm.password,
        password_confirmation: this.technicianForm.confirmPassword,
      };
      this.createTechnician(newTechnician);
      this.resetForm();
    },
    selectTechnician(technician) {
      this.technicianSelected = technician;
      this.technicianForm.email = technician.email;
      this.technicianForm.role = { ...technician.roles_id };
      this.technicianForm.password = "";
      this.technicianForm.confirmPassword = "";
    },
    update() {
      const updatedTechnician = {
        data: {
          email: this.technicianForm.email,
          roles_id: this.technicianForm.role.id,
        },
        id: this.technicianSelected.id,
      };
      this.updateTechnician(updatedTechnician);
      this.technicianSelected = updatedTechnician;
    },
    resetForm() {
      this.technicianForm = {
        email: "",
        role: { id: 2, name: "Technicien" },
        password: "",
        confirmPassword: "",
      };
      this.technicianSelected = null;
    },
  },
};
</script>

<style scoped>
.technician-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.technician-form {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.technician-form div {
  margin-bottom: 10px;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.technician-list-container {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.technician-list-header,
.technician-list-content {
  display: flex;
  flex-direction: column;
}

.technician-row {
  display: flex;
  width: 100%;
}

.technician-cell {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.header {
  background-color: #f2f2f2;
  font-weight: bold;
}

.technician-list-content {
  max-height: 400px;
  overflow-y: auto;
}

.technician-list-content::-webkit-scrollbar {
  width: 8px;
}

.technician-list-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 8px;
}

.technician-list-content::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.technician-row.selected {
  background-color: #e0f7fa;
}

.technician-row:hover {
  background-color: #f1f1f1;
}

select {
  width: 99% !important;
}

input,
select {
  margin-bottom: 10px;
  background-color: white;
  border-radius: 2px;
  padding: 10px;
  border: 1px solid var(--color-border);
  width: 94.5%;
}

input:focus,
select:focus {
  outline: none !important;
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 2px var(--color-primary);
}

button {
  align-items: center;
  margin: 5px;
  padding: 2px 20px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid var(--color-border);
  border-radius: 3px;
  background-color: white;
}

button:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
</style>
