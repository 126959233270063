<template>
  <div class="filter-container">
    <label for="client-filter">Filtrer par nom:</label>
    <input id="client-filter" v-model="filterText" @input="filterClients" />
  </div>
  <div class="client-list-container">
    <div class="client-list-header">
      <div class="client-row header-row">
        <div class="client-cell header">Client</div>
        <div class="client-cell header">N° Ticket</div>
      </div>
    </div>
    <div class="client-list-content">
      <div
        class="client-row"
        v-for="client in filteredClients"
        :key="client.id"
        @click="$emit('selectClient', client.id)"
      >
        <div class="client-cell">{{ client.name }}</div>
        <div class="client-cell">{{ getNbTickets(client.id) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ClientList",
  emits: ["selectClient"],
  data() {
    return {
      filterText: "",
    };
  },
  computed: {
    ...mapGetters({
      getClients: "getClients",
      getTickets: "getTickets",
    }),
    filteredClients() {
      const filterTextLower = this.filterText.toLowerCase();
      return [...this.getClients]
        .filter((client) => client.name.toLowerCase().includes(filterTextLower))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    // Récupère le nombre de tickets ouverts pour un client
    getNbTickets(idClient) {
      return this.getTickets.filter(
        (ticket) => ticket.client_id.id === idClient
      ).length;
    },
    filterClients() {
      // This method is here to trigger the computed property re-evaluation
    },
  },
};
</script>

<style scoped>
.client-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.client-list-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f1f1f1;
  border-bottom: 1px solid #ccc;
}

.client-row {
  display: flex;
  width: 100%;
  padding: 10px;
  box-sizing: border-box; /* Assure que les bordures sont incluses dans la largeur totale */
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
}

.client-row:hover:not(.header-row) {
  background-color: #e9e9e9;
}

.client-cell {
  flex: 1;
  padding: 10px;
  text-align: left;
}

.client-list-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

input,
select {
  margin-bottom: 10px;
  background-color: white;
  border-radius: 2px;
  padding: 10px;
  border: 1px solid var(--color-border);
  width: 94.5%;
}

input:focus,
select:focus {
  outline: none !important;
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 2px var(--color-primary);
}
</style>
