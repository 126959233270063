<template>
  <main>
    <div class="form-container">
      <h2>Réinitialisation du mot de passe</h2>
      <div>
        <label for="password" class="form-label">Nouveau mot de passe:</label>
        <input
          v-model="password"
          type="password"
          id="password"
          class="form-input"
        />
        <label for="confirmPassword" class="form-label"
          >Confirmer le mot de passe:</label
        >
        <input
          v-model="confirmPassword"
          type="password"
          id="confirmPassword"
          class="form-input"
        />
        <button @click="resetPass()" class="primary-button">
          Réinitialiser
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from "vuex";
import { showToast } from "@/sweetAlertService";

export default {
  name: "PasswordReset.vue",
  data() {
    return {
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    ...mapActions({
      resetPassword: "resetPassword",
    }),
    resetPass() {
      // Vérifi que les mots de passe ne sont pas vide
      if (this.password === "" || this.confirmPassword === "") {
        showToast("error", "Les mots de passe ne peuvent pas être vide");
        return;
      }

      // Vérifi que les mots de passe corresponde
      if (this.password !== this.confirmPassword) {
        showToast("error", "Les mots de passe ne correspondent pas");
        return;
      }

      let token = this.$route.params.token;

      this.resetPassword({
        password: this.password,
        password_confirmation: this.confirmPassword,
        token: token.toString(),
      });
    },
  },
};
</script>

<style scoped>
main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.form-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 450px;
  width: 100%;
  text-align: center;
}

h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-label {
  margin-bottom: 10px;
  font-weight: bold;
  color: #555;
}

input,
select {
  margin-bottom: 10px;
  background-color: white;
  border-radius: 2px;
  padding: 10px;
  border: 1px solid var(--color-border);
  width: 94.5%;
}

input:focus,
select:focus {
  outline: none !important;
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 2px var(--color-primary);
}
</style>
