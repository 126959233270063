import { createStore } from "vuex";
import axios from "axios";
import { showToast } from "@/sweetAlertService";

//axios.defaults.baseURL = "http://localhost:8000";
axios.defaults.baseURL = "https://api.steval.dev";

export default createStore({
  state: {
    user: JSON.parse(sessionStorage.getItem("user")) || [],
    clients: [],
    technicians: [],
    selectedTicket: "",
    tickets: [],
    priority: [],
    status: [],
    machines: [],
    types: [],
    CustomFields: [],
    Concerns: [],
  },
  getters: {
    getUser: (state) => state.user,
    getClients: (state) => state.clients,
    getTechnicians: (state) => state.technicians,
    getSelectedTicket: (state) => state.selectedTicket,
    getTickets: (state) => state.tickets,
    getPriority: (state) => state.priority,
    getStatus: (state) => state.status,
    getTicketById: (state) => (ticketId) => {
      return state.tickets.find((ticket) => ticket.id === ticketId);
    },
    getMachines: (state) => state.machines,
    getTypes: (state) => state.types,
    getCustomFields: (state) => state.CustomFields,
    getConcerns: (state) => state.Concerns,
  },
  mutations: {
    setUser: (state, user) => {
      state.user = user;
      sessionStorage.setItem("user", JSON.stringify(user));
    },
    setClients: (state, clients) => (state.clients = clients),
    setTechnicians: (state, technicians) => (state.technicians = technicians),
    setSelectedTicket: (state, selectedTicket) =>
      (state.selectedTicket = selectedTicket),
    setTickets: (state, tickets) => (state.tickets = tickets),
    setOneTicket: (state, payload) =>
      (state.tickets[payload.index] = payload.ticket),
    setPriority: (state, priority) => (state.priority = priority),
    setStatus: (state, status) => (state.status = status),
    /**
     * Met à jours les actions d'un ticket
     * @param state
     * @param payload
     */
    updateLocalActions: (state, payload) => {
      const index = state.tickets.findIndex(
        (ticket) => ticket.id === payload.ticketId
      );

      if (index !== -1) {
        state.tickets[index].actions = payload.updatedActions;
      }
    },
    setMachines: (state, machines) => (state.machines = machines),
    setTypes: (state, types) => (state.types = types),
    setCustomFields: (state, CustomFields) =>
      (state.CustomFields = CustomFields),
    setConcerns: (state, Concerns) => (state.Concerns = Concerns),
  },
  actions: {
    /**
     * Permet de connecter un techniciens
     */
    login: function ({ commit }, credentials) {
      if (credentials.email === "" || credentials.password === "") {
        showToast("error", "Veuillez remplir tous les champs");
        return;
      }
      return axios
        .post("/login", credentials)
        .then((response) => {
          commit("setUser", response.data);
          showToast("success", "Connexion réussie");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },
    /**
     * Permet de déconnecter un technicien
     */
    logout: function ({ commit, getters }) {
      return axios
        .post("/logout", null, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then(() => {
          commit("setUser", []);
          sessionStorage.removeItem("user");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },

    /**
     * Permet d'obtenir un ticket
     * @param ticketId l'identifiant du ticket
     */
    async getOneTicket({ getters }, ticketId) {
      return axios
        .get("/tickets/" + ticketId, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },

    /**
     * Permet d'obtenir tous les tickets
     */
    async getAllTickets({ commit, getters }) {
      return axios
        .get("/tickets", {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then((response) => {
          commit("setTickets", response.data);
          return response.data;
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },

    /**
     * Permet d'obtenir tous les clients
     */
    getAllClients({ commit, getters }) {
      return axios
        .get("/clients", {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then((response) => {
          commit("setClients", response.data);
        })
        .catch((error) => {
          // Si le code est 401 alors on déconnecte l'utilisateur
          if (error.response.status === 401) {
            commit("setUser", []);
            sessionStorage.removeItem("user");

            // recharge la page
            location.reload();
          } else {
            showToast("error", error.response.data.message);
          }
        });
    },

    /**
     * Permet d'obtenir tous les techniciens
     */
    getAllTechnicians({ commit, getters }) {
      return axios
        .get("/technicians", {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then((response) => {
          commit("setTechnicians", response.data);
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },

    /**
     * Permet d'obtenir toutes les priorités
     */
    getAllPriorities({ commit, getters }) {
      return axios
        .get("/priorities", {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then((response) => {
          commit("setPriority", response.data);
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },

    /**
     * Met à jour une priorité
     */
    updatePriority({ getters }, priority) {
      return axios
        .put("/priorities/" + priority.id, priority.data, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then(() => {
          showToast("success", "Priorité modifiée avec succès");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },

    /**
     * Crée une priorité
     */
    createPriority({ getters }, priority) {
      return axios
        .post("/priorities", priority, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then(() => {
          showToast("success", "Priorité créée avec succès");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },

    /**
     * Supprime une priorité
     */
    deletePriority: function ({ getters }, priorityId) {
      return axios
        .delete("/priorities/" + priorityId, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then(() => {
          showToast("success", "Priorité supprimée avec succès");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },

    /**
     * Permet d'obtenir tous les status
     */
    getAllStatus({ commit, getters }) {
      return axios
        .get("/states", {
          headers: { Authorization: "Bearer " + getters.getUser.access_token },
        })
        .then((response) => {
          commit("setStatus", response.data);
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },

    /**
     * Met à jour un status
     */
    updateStatus({ getters }, status) {
      return axios
        .put("/states/" + status.id, status.data, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then(() => {
          showToast("success", "Status modifié avec succès");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },

    /**
     * Crée un status
     */
    createStatus({ getters }, status) {
      return axios
        .post("/states", status, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then(() => {
          showToast("success", "Status créé avec succès");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },

    /**
     * Supprime un status
     */
    deleteStatus({ getters }, statusId) {
      return axios
        .delete("/states/" + statusId, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then(() => {
          showToast("success", "Status supprimé avec succès");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },

    /**
     * Permet de modifier un ticket
     * @param ticket le ticket à modifier
     */
    updateTicket({ getters }, ticket) {
      // Modifi le ticket pour correspondre à l'API
      const id = ticket.id;

      // supprimer l'attribut id
      delete ticket.id;

      return axios
        .put("/tickets/" + id, ticket, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then(() => {
          showToast("success", "Ticket modifié avec succès");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },
    /**
     * Permet la création d'un ticket
     * @param ticket le ticket à créer
     */
    createTicket({ commit, getters }, ticket) {
      return axios
        .post("/tickets", ticket, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then((response) => {
          showToast("success", "Ticket créé avec succès");

          // Ajoutez une écoute pour vérifier l'ajout du ticket
          let checkTicketAdded = setInterval(() => {
            let allTickets = getters.getTickets;
            let lastTicket = allTickets[allTickets.length - 1];
            if (lastTicket && lastTicket.id === response.data.ticket.id) {
              clearInterval(checkTicketAdded);
              commit("setSelectedTicket", response.data.ticket.id);
            }
          }, 100); // Vérifie toutes les 100ms
        })
        .catch((error) => {
          showToast("error", error);
        });
    },
    /**
     * Permet la suppression d'un ticket
     * @param ticketId l'identifiant du ticket à supprimer
     */
    deleteTicket({ getters }, ticketId) {
      return axios
        .delete("/tickets/" + ticketId, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then(() => {
          showToast("success", "Ticket supprimé avec succès");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },
    /**
     * Mise à jours d'une actions
     * @param action liste des actions à mettre à jours
     */
    updateAction({ commit, getters }, action) {
      let actionId = "";
      let ticketId = "";

      action.forEach((act) => {
        actionId = act.id;
        ticketId = act.ticket_id;
        let actionData = "";

        actionData = {
          date: act.date,
          description: act.description,
          duration: act.duration,
        };

        // En fonction de l'état de l'action appel la bonne route de l'API
        switch (act.state) {
          case "modified":
            return axios
              .put(
                "/tickets/" + ticketId + "/actions/" + actionId,
                actionData,
                {
                  headers: {
                    Authorization: "Bearer " + getters.getUser.access_token,
                  },
                }
              )
              .catch((error) => {
                showToast("error", error.response.data.message);
              });
          case "deleted":
            return axios
              .delete("/tickets/actions/" + actionId, {
                headers: {
                  Authorization: "Bearer " + getters.getUser.access_token,
                },
              })
              .catch((error) => {
                showToast("error", error.response.data.message);
              });
          case "new":
            return axios
              .post("/tickets/" + ticketId + "/actions", actionData, {
                headers: {
                  Authorization: "Bearer " + getters.getUser.access_token,
                },
              })
              .catch((error) => {
                showToast("error", error.response.data.message);
              });
        }
      });

      // supprime ceux qui avait "deleted"
      action = action.filter((act) => act.state !== "deleted");

      // supprime la propriété state
      action.forEach((act) => {
        delete act.state;
      });

      commit("updateLocalActions", {
        updatedActions: action,
        ticketId: ticketId,
      });
    },

    /**
     * Rafraichi le token
     * refreshToken
     */
    refreshToken({ commit, getters }) {
      return axios
        .post("/refresh", null, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then((response) => {
          commit("setUser", response.data);
          sessionStorage.setItem("user", JSON.stringify(response.data));
        });
    },

    /**
     * Modifi un technicien
     * @param technician le technicien à modifier
     */
    updateTechnician({ getters }, technician) {
      return axios
        .put("/technicians/" + technician.id, technician.data, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then(() => {
          showToast("success", "Technicien modifié avec succès");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },
    /**
     * Créer un technicien
     * @param technician le technicien à créer
     */
    createTechnician({ getters }, technician) {
      return axios
        .post("/technicians", technician, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then(() => {
          showToast("success", "Technicien créé avec succès");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },
    /**
     * Récupère toutes les machines
     */
    getAllMachines({ commit, getters }) {
      return axios
        .get("/machines", {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then((response) => {
          commit("setMachines", response.data);
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },
    /**
     * Crée une machine
     */
    async createMachine({ getters }, machine) {
      try {
        const response = await axios.post("/machines", machine, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        });
        showToast("success", "Machine créé avec succès");
        return response.data;
      } catch (error) {
        showToast("error", error.response.data.message);
      }
    },
    /**
     * Modifi une machine
     */
    updateMachine({ getters }, machine) {
      return axios
        .put("/machines/" + machine.id, machine.data, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then(() => {
          showToast("success", "Machine modifié avec succès");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },
    /**
     * Supprime une machine
     */
    deleteMachine({ getters }, machineId) {
      return axios
        .delete("/machines/" + machineId, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then(() => {
          showToast("success", "Machine supprimé avec succès");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },
    /**
     * Récupère tous les types
     */
    getAllTypes({ commit, getters }) {
      return axios
        .get("/types", {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then((response) => {
          commit("setTypes", response.data);
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },
    /**
     * Crée un type
     */
    async createType({ getters }, type) {
      try {
        const response = await axios.post("/types", type, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        });
        showToast("success", "Type créé avec succès");
        return response.data;
      } catch (error) {
        showToast("error", error.response.data.message);
      }
    },
    /**
     * Modifi un type
     */
    updateType({ getters }, type) {
      return axios
        .put("/types/" + type.id, type.data, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then(() => {
          showToast("success", "Type modifié avec succès");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },
    /**
     * Supprime un type
     */
    deleteType({ getters }, typeId) {
      return axios
        .delete("/types/" + typeId, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then(() => {
          showToast("success", "Type supprimé avec succès");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },
    /**
     * Met à jours les machines des tickets
     */
    updateTicketMachine({ getters }, payload) {
      return axios.post("/tickets/" + payload.id + "/concerns", payload.data, {
        headers: {
          Authorization: "Bearer " + getters.getUser.access_token,
        },
      });
    },
    /**
     * Envoie l'email de réinitialisation de mot de passe
     */
    // eslint-disable-next-line no-unused-vars
    sendResetPasswordEmail({ getters }, email) {
      return axios
        .post("/password/email", email)
        .then(() => {
          showToast("success", "Email envoyé avec succès");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },
    /**
     * Réinitialise le mot de passe
     */
    // eslint-disable-next-line no-unused-vars
    resetPassword({ getters }, data) {
      return axios
        .post("/password/reset", data)
        .then(() => {
          showToast("success", "Mot de passe réinitialisé avec succès");

          // redirige vers la page de connexion
          location.href = "/#/login";
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },
    /**
     * Récupère les champs personnalisés
     */
    getAllCustomFields({ commit, getters }) {
      return axios
        .get("/custom-fields", {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then((response) => {
          commit("setCustomFields", response.data);
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },
    /**
     * Crée un champ personnalisé
     */
    createOrUpdateCustomField({ getters }, customField) {
      return axios.post("/custom-fields", customField, {
        headers: {
          Authorization: "Bearer " + getters.getUser.access_token,
        },
      });
    },
    /**
     * Supprime un champ personnalisé
     */
    deleteCustomField({ getters }, customFieldId) {
      return axios.delete("/custom-fields/" + customFieldId, {
        headers: {
          Authorization: "Bearer " + getters.getUser.access_token,
        },
      });
    },
    /**
     * Récupère les valeur des champs personnalisé
     */
    async getCustomFieldValues({ getters }, machineId) {
      try {
        const response = await axios.get(
          "/custom-fields/machines/" + machineId,
          {
            headers: {
              Authorization: "Bearer " + getters.getUser.access_token,
            },
          }
        );
        return response.data;
      } catch (error) {
        showToast("error", error.response.data.message);
      }
    },
    /**
     * Met à jours les valeurs des champs personnalisé
     */
    createOrUpdateCustomFieldValues({ getters }, data) {
      return axios.post(
        "/custom-fields/machines/" + data.machine_id,
        data.data,
        {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        }
      );
    },
    /**
     * ce met à jours
     */
    updateMe({ commit, getters }, data) {
      return axios
        .put("/technicians/me", data, {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then((response) => {
          commit("setUser", response.data);
          sessionStorage.setItem("user", JSON.stringify(response.data));
          showToast("success", "Profil modifié avec succès");
        })
        .catch((error) => {
          showToast("error", error.response.data.message);
        });
    },
    /**
     * Récupère la liste des concerns
     */
    getAllConcerns({ commit, getters }) {
      return axios
        .get("/concerns", {
          headers: {
            Authorization: "Bearer " + getters.getUser.access_token,
          },
        })
        .then((response) => {
          commit("setConcerns", response.data);
        });
    },
    /**
     * Met à jours un concern
     */
    updateConcern({ getters }, concern) {
      return axios.post("/tickets/" + concern.id + "/concerns", concern.data, {
        headers: {
          Authorization: "Bearer " + getters.getUser.access_token,
        },
      });
    },
  },
  modules: {},
});
