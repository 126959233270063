<template>
  <main>
    <div class="admin-container">
      <div class="sidebar">
        <ul>
          <li
            v-for="item in menuItems"
            :key="item.value"
            @click="setActive(item.value)"
            :class="{ active: activeItem === item.value }"
          >
            {{ item.text }}
          </li>
        </ul>
      </div>
      <div class="component-container">
        <component :is="selectedComponent"></component>
      </div>
    </div>
  </main>
</template>

<script>
import MachineManager from "@/components/MachineManager.vue";
import TechnicianView from "@/views/TechnicianView.vue";
import TypeManager from "@/components/TypeManager.vue";
import StateManager from "@/components/StateManager.vue";
import TicketView from "@/views/TicketView.vue";
import PriorityManager from "@/components/PriorityManager.vue";

export default {
  name: "AdminView",
  components: {
    MachineManager,
    TechnicianView,
    TypeManager,
    TicketView,
    StateManager,
    PriorityManager,
  },
  data() {
    return {
      selectedComponent: "MachineManager",
      activeItem: "MachineManager",
      menuItems: [
        { text: "Machine", value: "MachineManager" },
        { text: "Type", value: "TypeManager" },
        { text: "Statut", value: "StateManager" },
        { text: "Priorité", value: "PriorityManager" },
        { text: "Technicien", value: "TechnicianView" },
        { text: "Ticket", value: "TicketView" },
      ],
    };
  },
  methods: {
    setActive(item) {
      this.selectedComponent = item;
      this.activeItem = item;
    },
  },
};
</script>

<style scoped>
.admin-container {
  display: flex;
}

.sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 20px;
  height: 100vh;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  flex-shrink: 0; /* Ensure the sidebar does not shrink */
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.sidebar ul li:hover {
  background-color: #e9e9e9;
}

.sidebar ul li.active {
  background-color: #ddd;
  color: #333;
  font-weight: bold;
}

.component-container {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}

@media (max-width: 1180px) {
  .admin-container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
    position: static;
    box-shadow: none;
    padding-bottom: 0;
  }

  .sidebar ul li {
    display: inline;
    overflow-x: auto;
  }

  .component-container {
    padding: 0;
  }
}
</style>
