<template>
  <div>
    <div class="actions-container">
      <li v-for="(action, index) in filteredActions" :key="action.id">
        <div class="action-row">
          <div class="input-container">
            <div class="input-div">
              <p class="title-list">Date :</p>
              <input
                :disabled="ticketClosed"
                type="date"
                v-model="action.date"
                @change="updateActionField(index)"
              />
            </div>
            <div class="input-div">
              <p class="title-list">Durée :</p>
              <input
                :disabled="ticketClosed"
                class="input-duration"
                type="time"
                v-model="action.duration"
                @change="updateActionField(index)"
              />
            </div>
          </div>
          <button
            :disabled="ticketClosed"
            class="secondary-button-2"
            @click="removeActions(index)"
          >
            x
          </button>
        </div>
        <div>
          <p class="title-list">Description</p>
          <textarea
            :disabled="ticketClosed"
            cols="60"
            maxlength="250"
            rows="2"
            wrap="hard"
            v-model="action.description"
            @change="updateActionField(index)"
          />
        </div>
      </li>
    </div>
    <div class="button-container">
      <button
        :disabled="ticketClosed"
        class="primary-button"
        @click="addActions"
      >
        Ajouter une action
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ActionsInTicket",
  props: {
    ticketId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      actions: [],
    };
  },
  computed: {
    ...mapGetters({
      getTicketById: "getTicketById",
    }),
    filteredActions() {
      // filtre les actions par date
      return [
        ...this.actions.filter((action) => action.state !== "deleted"),
      ].sort((a, b) => a.date.localeCompare(b.date));
    },
    ticketClosed() {
      return this.getTicketById(this.ticketId).state_id === 3;
    },
  },
  methods: {
    // Récupère les actions du ticket
    getActions() {
      this.actions = [];
      if (this.getTicketById(this.ticketId).actions) {
        this.actions = JSON.parse(
          JSON.stringify(this.getTicketById(this.ticketId).actions)
        );
      }

      // Initialise le status de chaque actions
      this.actions.forEach((action) => {
        action.state = "initial";
      });
    },
    // Crée une nouvelle action
    addActions() {
      this.actions.push({
        date: new Date().toISOString().split("T")[0],
        duration: "",
        description: "",
        ticket_id: this.ticketId,
        state: "new",
      });
    },
    // Met à jour le status de l'action
    updateActionField(index) {
      const action = this.actions[index];
      if (action.state === "initial") {
        action.state = "modified";
      }
    },
    // Supprime une action
    removeActions(index) {
      this.actions[index].state = "deleted";
    },
  },
  watch: {
    ticketId: {
      handler: "getActions",
      immediate: true,
    },
  },
};
</script>

<style scoped>
.actions-container {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.button-container {
  display: flex;
  justify-content: center;
}

li {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--color-border);
}

.title-list {
  padding: 0;
  display: ruby;
  margin: 0 10px 0 0;
}

.action-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-container {
  flex-grow: 1;
  display: flex;
  gap: 2em;
}

textarea {
  resize: vertical;
}

textarea,
input {
  margin-bottom: 10px;
  background-color: white;
  border-radius: 2px;
  padding: 10px;
  border: 1px solid var(--color-border);
  width: 94.5%;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

.input-div {
  width: min-content;
}

.input-duration {
  width: 65% !important;
}

textarea:focus,
input:focus {
  outline: none !important;
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 2px var(--color-primary);
}
</style>
