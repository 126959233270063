import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AdminView from "@/views/AdminView.vue";
import store from "@/store";
import PasswordView from "@/views/PasswordView.vue";
import ClientView from "@/views/ClientView.vue";
import UserProfile from "@/components/UserProfile.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
  },
  {
    path: "/password",
    name: "password",
    component: PasswordView,
  },
  {
    path: "/password/reset/:token",
    name: "password-reset",
    component: PasswordView,
  },
  {
    path: "/clients",
    name: "clients",
    component: ClientView,
  },
  {
    path: "/me",
    name: "me",
    component: UserProfile,
  },
];

// Si l'utilisateur n'a pas le role "admin", on redirige vers la page d'accueil si il tente d'accéder à la page "technicien"
const adminRoute = routes.find((route) => route.name === "admin");
adminRoute.beforeEnter = (to, from, next) => {
  if (store.getters.getUser.user.roles_id !== 3) {
    next({ name: "home" });
  } else {
    next();
  }
};

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Mettre à jour la variable dans Vuex lors du changement de route
  store.commit("setSelectedTicket", undefined);
  next();
});

export default router;
