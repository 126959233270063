<template>
  <div class="client-details">
    <div class="ticket-container">
      <h2 @click="toggleTickets">
        <span :class="{ rotated: showTickets }">&#9654;</span>
        Tickets
      </h2>
      <div v-show="showTickets">
        <div class="filter">
          <label for="status-filter">Statut des ticket:</label>
          <multiselect
            id="status-filter"
            v-model="selectedStatus"
            :options="getStatus"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            track-by="name"
            label="name"
            :allow-empty="false"
          ></multiselect>
        </div>
        <div class="ticket-list">
          <li
            v-for="ticket in filteredTickets"
            :key="ticket.id"
            :class="{ selected: getSelectedTicket === ticket.id }"
            @click="selectTicket(ticket.id)"
          >
            <simplified-ticket :ticket-id="ticket.id" />
          </li>
          <p v-if="filteredTickets.length === 0">Aucun tickets</p>
        </div>
        <button class="primary-button" @click="createTicket">
          Créer un ticket
        </button>
      </div>
    </div>
    <div class="machine-container">
      <h2 @click="toggleMachines">
        <span :class="{ rotated: showMachines }">&#9654;</span>
        Machines
      </h2>
      <div v-show="showMachines">
        <div class="machine-list">
          <ol>
            <li
              v-for="machine in getMachinesForClient(client.id)"
              :key="machine.id"
            >
              <h3>{{ machine.name }}</h3>
            </li>
          </ol>
          <p v-if="getMachinesForClient(client.id).length === 0">
            Aucune machine
          </p>
        </div>
      </div>
    </div>

    <ModalRightPanel
      v-if="isModalVisible"
      :usingFor="modalUsingFor"
      @close-modal="isModalVisible = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import simplifiedTicket from "@/components/SimplifiedTicket.vue";
import ModalRightPanel from "@/components/ModalRightPanel.vue";
import Multiselect from "vue-multiselect";

export default {
  name: "ClientDetails",
  components: { simplifiedTicket, ModalRightPanel, Multiselect },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showTickets: true,
      showMachines: false,
      selectedStatus: "",
      isModalVisible: false,
      modalUsingFor: "Enregistrer",
    };
  },
  computed: {
    ...mapGetters({
      getTickets: "getTickets",
      getSelectedTicket: "getSelectedTicket",
      getMachines: "getMachines",
      getStatus: "getStatus",
    }),
    filteredTickets() {
      if (this.showTickets) {
        return this.getTicketsForClient(this.client.id, this.selectedStatus.id);
      }
      return false;
    },
  },
  methods: {
    ...mapMutations(["setSelectedTicket"]),
    selectTicket(id) {
      this.setSelectedTicket(id);
      this.modalUsingFor = "Enregistrer";
      this.isModalVisible = true;
    },
    toggleTickets() {
      this.showTickets = !this.showTickets;
    },
    toggleMachines() {
      this.showMachines = !this.showMachines;
    },
    getTicketsForClient(idClient, stateIds) {
      return this.getTickets.filter(
        (ticket) =>
          ticket.client_id.id === idClient && ticket.state_id === stateIds
      );
    },
    getMachinesForClient(idClient) {
      return this.getMachines.filter(
        (machine) => machine.client_id === idClient
      );
    },
    createTicket() {
      this.setSelectedTicket(undefined);
      this.modalUsingFor = "Créer";
      this.isModalVisible = true;
    },
  },
  created() {
    this.selectedStatus = this.getStatus[0];
  },
};
</script>

<style scoped>
.client-details {
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 160px;
}

h2 {
  cursor: pointer;
  display: flex;
  align-items: center;
}

h2 span {
  font-size: 0.7em; /* Adjust this value to make the indicator smaller */
  margin-right: 10px;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center; /* Ensure the indicator is vertically centered */
}

h2 span.rotated {
  transform: rotate(90deg);
}

ul {
  list-style-type: none;
  padding: 0;
}

.ticket-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.ticket-list li {
  margin: 0.6rem;
  padding: 0.5rem;
  border: 1px solid var(--color-border);
  border-radius: 2px;
  cursor: pointer;
}

li {
  margin: 5px 0;
}

.filter {
  width: 200px;
}

.ticket-container,
.machine-container {
  padding: 10px;
}

select {
  margin-bottom: 10px;
  background-color: white;
  border-radius: 2px;
  padding: 10px;
  border: 1px solid var(--color-border);
}

select:focus {
  outline: none !important;
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 2px var(--color-primary);
}

p {
  margin: 10px 0;
  color: #888;
}
</style>
