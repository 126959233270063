<template>
  <div class="status-manager">
    <h2>Status</h2>
    <div class="list-header">
      <span class="header-item">Nom du Status</span>
      <span class="header-item">Actions</span>
    </div>
    <ul class="status-list">
      <li v-for="state in status" :key="state.id" @click="selectStatus(state)">
        <span class="status-item">{{ state.name }}</span>
        <span class="status-item">
          <button class="delete-button" @click.stop="confirmDelete(state.id)">
            Supprimer
          </button>
        </span>
      </li>
    </ul>
    <button class="primary-button" @click="openModal(null)">
      Créer un nouveau statut
    </button>
    <modal v-if="showModal" @close="closeModal">
      <h3>
        {{ selectedStatus ? "Modifier le status" : "Créer un Nouveau status" }}
      </h3>
      <div>
        <label>Nom du status:</label>
        <input v-model="statusForm.name" maxlength="254" />
      </div>
      <div class="bottom-button">
        <button class="primary-button" @click="saveStatus" :disabled="wait">
          <img
            v-if="wait"
            src="@/assets/spinner.gif"
            alt="Loading..."
            class="spinner"
          />
          {{ selectedStatus ? "Mettre à jour" : "Créer" }}
        </button>
        <button class="secondary-button" @click="closeModal">Fermer</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
import { showToast } from "@/sweetAlertService";

export default {
  name: "StateManager",
  components: { Modal },
  data() {
    return {
      showModal: false,
      selectedStatus: null,
      statusForm: {
        name: "",
      },
      status: [],
      wait: false,
    };
  },
  computed: {
    ...mapGetters({
      getStatus: "getStatus",
      getTickets: "getTickets",
    }),
  },
  methods: {
    ...mapActions([
      "createStatus",
      "updateStatus",
      "deleteStatus",
      "getAllStatus",
    ]),
    async selectStatus(state) {
      this.selectedStatus = state;
      this.statusForm.name = state.name;
      this.showModal = true;
    },
    async saveStatus() {
      this.wait = true;
      // Vérifie que les champs sont remplis
      if (!this.statusForm.name) {
        showToast("error", "Veuillez remplir tous les champs");
        return;
      }

      if (this.selectedStatus) {
        const updatedState = {
          data: {
            name: this.statusForm.name,
          },
          id: this.selectedStatus.id,
        };
        await this.updateStatus(updatedState);
      } else {
        // Création du type
        const newState = {
          name: this.statusForm.name,
        };
        await this.createStatus(newState);
      }

      await Promise.all([this.getAllStatus()]);
      this.wait = false;
      this.status = this.getStatus;
      this.closeModal();
    },
    openModal(state) {
      this.selectedStatus = state;
      this.statusForm = state ? { ...state } : { name: "" };
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedStatus = null;

      // Reset the form
      this.statusForm = {
        name: "",
      };
    },
    async confirmDelete(stateId) {
      // il ne peux pas y avoir 0 status
      const status = this.getStatus;

      // Si un ticket utilise le status error
      const ticketWithStatus = this.getTickets.find(
        (ticket) => ticket.state_id === stateId
      );
      if (ticketWithStatus) {
        showToast(
          "error",
          "Vous ne pouvez pas supprimer ce status car il est utilisé par un ticket."
        );
        return;
      }

      if (status.length === 1) {
        showToast(
          "error",
          "Vous ne pouvez pas supprimer le dernier status. Vous devez en avoir au moins un."
        );
        return;
      }
      await this.deleteStatus(stateId);
      await this.getAllStatus();
      this.status = this.getStatus;
    },
  },
  async mounted() {
    this.status = this.getStatus;
  },
};
</script>

<style scoped>
.status-manager {
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0 0 20px;
}

.list-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 0.5rem;
  border-bottom: 1px solid var(--color-border);
}

.header-item {
  flex: 1;
}

.header-item:last-child {
  flex: 0;
  text-align: right;
}

.status-list {
  list-style-type: none;
  padding: 0;
  max-height: 600px;
  overflow-y: auto;
}

.status-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.6rem 0;
  padding: 0.5rem;
  border: 1px solid var(--color-border);
  border-radius: 2px;
  cursor: pointer;
}

.status-item {
  flex: 1;
  overflow-y: hidden;
}

.status-item:last-child {
  flex: 0;
  text-align: right;
  overflow-y: visible;
}

.delete-button {
  background-color: red;
  color: white;
  border: none;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
}

.delete-button:hover {
  background-color: darkred;
}

input,
select {
  margin-bottom: 10px;
  background-color: white;
  border-radius: 2px;
  padding: 10px;
  border: 1px solid var(--color-border);
  width: 94.5%;
}

input:focus,
select:focus {
  outline: none !important;
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 2px var(--color-primary);
}

.bottom-button {
  display: flex;
  justify-content: space-between;
}

.spinner {
  width: 12px;
  height: 12px;
}
</style>
