<template>
  <div class="modal" v-if="isVisible">
    <div class="modal-content">
      <span class="close" @click="close">&times;</span>
      <h3>Machines</h3>
      <ul v-if="machines.length > 0">
        <li
          v-for="machine in machines"
          :key="machine.id"
          :class="{ selected: selectedMachines.includes(machine.id) }"
          @click="toggleSelection(machine.id)"
        >
          {{ machine.name }}
        </li>
      </ul>
      <p v-else>Ce client ne possède aucune machine.</p>
      <button class="primary-button" @click="confirmSelection">
        Confirmer
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MachineModal",
  props: {
    machines: Array,
    isVisible: Boolean,
    initialSelected: Array,
  },
  data() {
    return {
      selectedMachines: this.initialSelected || [],
    };
  },
  methods: {
    close() {
      this.$emit("update:isVisible", false);
    },
    toggleSelection(machineId) {
      const index = this.selectedMachines.indexOf(machineId);
      if (index > -1) {
        this.selectedMachines.splice(index, 1);
      } else {
        this.selectedMachines.push(machineId);
      }
    },
    confirmSelection() {
      this.$emit("machines-selected", this.selectedMachines);
      this.close();
    },
  },
  watch: {
    initialSelected(newVal) {
      if (newVal) {
        this.selectedMachines = [...newVal];
      }
    },
    isVisible(val) {
      if (val) {
        this.selectedMachines = [...this.initialSelected];
      }
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
}

.close {
  float: right;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

li:hover,
li.selected,
li.selected:hover {
  background-color: var(--color-primary);
  color: white;
}
</style>
