<template>
  <!-- Oblige l'utilisateur à être connecté pour pouvoir utilisé l'application -->
  <PasswordView v-if="noNeedAuth" />
  <LoginView v-else-if="!isAuthenticated" />
  <AppView v-else />
</template>

<script setup>
import LoginView from "@/views/LoginView.vue";
import AppView from "@/views/AppView.vue";
import PasswordView from "@/views/PasswordView.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();

// Variable qui permet de savoir si l'utilisateur est connecté
const isAuthenticated = computed(() => !!store.getters.getUser.access_token);
const noNeedAuth = computed(() => route.path.includes("password"));
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
/* Palette de couleurs */
:root {
  --color-primary: #0a2a41;
  --color-secondary: #7c858b;
  --color-text: #333;
  --color-text-light: #fff;
  --color-border: #ccc;
  --color-border-dark: #666;
  --color-background: #f6f6fb;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 93vh;
}

html,
body,
main {
  min-height: 93vh;
  background-color: var(--color-background);
  color: var(--color-text);
  margin: 0;
  padding: 0;
}

header {
  background-color: var(--color-primary);
  color: var(--color-text-light);
  padding: 10px 20px;
  height: 4em;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

main {
  margin-top: 4em;
}

.logo {
  max-height: 100%;
  object-fit: contain;
}

/*******************************************
Toast
*******************************************/
body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(15px);
  position: absolute;
}

.swal2-close {
  color: var(--color-text) !important;
}

.colored-toast.swal2-icon-warning {
  background-color: #f8bb86 !important;
  color: white;
}

.colored-toast.swal2-icon-error {
  background-color: #f27474 !important;
  color: white;
}

.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
  color: white;
}

.swal2-popup {
  font-family: Chakra Petch, serif;
}

/*******************************************
Button
*******************************************/
.primary-button {
  margin-top: 10px;
  padding: 10px;
  width: 20vh;
  border: 1px solid var(--color-primary);
  border-radius: 0.2em;
  background-color: var(--color-primary);
  color: var(--color-text-light);
  cursor: pointer;
  font-weight: bold;
}

.secondary-button {
  margin-top: 10px;
  padding: 10px;
  width: 15vh;
  border: 1px solid var(--color-border-dark);
  border-radius: 0.2em;
  background-color: var(--color-background);
  color: var(--color-text);
  cursor: pointer;
  font-weight: bold;
}

.secondary-button:hover {
  background-color: #eaeafa;
}

/**
Multiselect
 */
.multiselect {
  border-radius: 2px;
  width: 100%;
}

.multiselect__element:hover {
  background-color: var(--color-primary);
}

.multiselect__content {
  max-height: 200px;
  overflow-y: auto;
}

.multiselect__option--highlight {
  background-color: var(--color-primary) !important;
  color: #fff;
}

.multiselect__option--selected {
  color: #000;
}

.multiselect__single,
.multiselect__placeholder {
  padding-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 100%;
}

.multiselect__tags {
  display: flex;
  flex-wrap: nowrap;
  border-radius: 2px;
}

.multiselect__tags-wrap {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
}
</style>
