<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Modal",
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.modal-container {
  width: 500px;
  background: white;
  border-radius: 2px;
  overflow: hidden;
  padding: 20px;
}

.modal-default-button {
  float: right;
}

@media (max-width: 1180px) {
  .modal-container {
    width: auto;
    border-radius: 0;
  }
}
</style>
