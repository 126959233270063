<template>
  <main>
    <div v-if="idClientSelected" class="selected-client">
      <button @click="clearSelection">Annuler la sélection</button>
      <span>{{ getClientName(idClientSelected) }}</span>
    </div>
    <div v-if="idClientSelected" class="client-details-container">
      <ClientDetails :client="selectedClient" />
    </div>
    <ClientList v-else @selectClient="idClientSelected = $event" />
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import ClientList from "@/components/ClientList.vue";
import ClientDetails from "@/components/ClientDetails.vue";

export default {
  name: "ClientView",
  components: {
    ClientList,
    ClientDetails,
  },
  data() {
    return {
      idClientSelected: null,
    };
  },
  computed: {
    ...mapGetters({
      getClients: "getClients",
    }),
    selectedClient() {
      return this.getClients.find(
        (client) => client.id === this.idClientSelected
      );
    },
  },
  methods: {
    clearSelection() {
      this.idClientSelected = null;
    },
    getClientName(id) {
      const client = this.getClients.find((client) => client.id === id);
      return client ? client.name : "";
    },
  },
  watch: {
    idClientSelected() {
      // reset le scroll
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
main {
  margin-top: 108px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 18px;
}

.selected-client {
  position: fixed;
  top: 85px;
  left: 0;
  width: 100vw; /* Full viewport width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 18px; /* Same padding as main for consistency */
  background-color: #f1f1f1;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  z-index: 100; /* Ensure the div stays on top */
}

.selected-client button {
  margin-right: 10px;
}

.client-details-container {
  margin-top: 130px; /* Adjust this if needed to avoid overlapping with the fixed header and selected client div */
}
</style>
