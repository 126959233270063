<template>
  <div class="type-manager">
    <h2>Types</h2>
    <div class="list-header">
      <span class="header-item">Nom du Type</span>
      <span class="header-item">Description</span>
      <span class="header-item">Champs Personnalisés</span>
      <span class="header-item">Actions</span>
    </div>
    <ul class="type-list">
      <li v-for="type in types" :key="type.id" @click="selectType(type)">
        <span class="type-item">{{ type.name }}</span>
        <span class="type-item">{{ type.description }}</span>
        <span class="type-item">{{ getCustomFieldsNames(type.id) }}</span>
        <span class="type-item">
          <button class="delete-button" @click.stop="confirmDelete(type.id)">
            Supprimer
          </button>
        </span>
      </li>
    </ul>
    <button class="primary-button" @click="openModal(null)">
      Créer un nouveau type
    </button>
    <modal v-if="showModal" @close="closeModal">
      <h3>
        {{ selectedType ? "Modifier le Type" : "Créer un Nouveau Type" }}
      </h3>
      <div>
        <label>Nom du Type:</label>
        <input v-model="typeForm.name" maxlength="254" />
      </div>
      <div>
        <label>Description:</label>
        <input v-model="typeForm.description" maxlength="254" />
      </div>
      <div>
        <h4>Champs Personnalisés</h4>
        <ul class="custom-field-list">
          <li v-for="field in filteredCustomFields" :key="field.id">
            <input
              v-model="field.name"
              @input="updateCustomField(field.id)"
              placeholder="Nom du champ"
              maxlength="254"
            />
            <button @click="removeCustomField(field.id)">Supprimer</button>
          </li>
        </ul>
        <button class="primary-button" @click="addCustomField">
          Ajouter un Champ
        </button>
      </div>
      <div class="bottom-button">
        <button class="primary-button" @click="saveType" :disabled="wait">
          <img
            v-if="wait"
            src="@/assets/spinner.gif"
            alt="Loading..."
            class="spinner"
          />
          {{ selectedType ? "Mettre à jour" : "Créer" }}
        </button>
        <button class="secondary-button" @click="closeModal">Fermer</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
import { showToast } from "@/sweetAlertService";
import { v4 as uuidv4 } from "uuid"; // Use UUID for generating unique IDs

export default {
  name: "TypeManager",
  components: { Modal },
  data() {
    return {
      showModal: false,
      selectedType: null,
      typeForm: {
        name: "",
        description: "",
        customFields: [],
      },
      types: [],
      wait: false,
    };
  },
  computed: {
    ...mapGetters({
      getTypes: "getTypes",
      getCustomFields: "getCustomFields",
      getMachines: "getMachines",
    }),
    filteredCustomFields() {
      return this.typeForm.customFields.filter(
        (field) => field.tag !== "deleted"
      );
    },
  },
  methods: {
    ...mapActions([
      "createType",
      "updateType",
      "deleteType",
      "createOrUpdateCustomField",
      "deleteCustomField",
      "getAllCustomFields",
      "getAllTypes",
    ]),
    getCustomFieldsNames(typeId) {
      const fields = this.getCustomFields.filter(
        (field) => field.type_id === typeId
      );
      return fields.map((field) => field.name).join(", ");
    },
    async selectType(type) {
      this.selectedType = type;
      this.typeForm.name = type.name;
      this.typeForm.description = type.description;

      let custom = this.getCustomFields;
      custom = custom.filter((field) => field.type_id === type.id);
      if (custom === undefined) {
        custom = [];
      }

      this.typeForm.customFields = custom.map((field) => ({
        ...field,
        tag: null, // Clear any previous tags
      }));
      this.showModal = true;
    },
    addCustomField() {
      this.typeForm.customFields.push({
        id: uuidv4(),
        name: "",
        tag: "created",
      });
    },
    removeCustomField(id) {
      const field = this.typeForm.customFields.find((f) => f.id === id);
      if (field.id) {
        field.tag = "deleted";
      } else {
        this.typeForm.customFields = this.typeForm.customFields.filter(
          (f) => f.id !== id
        );
      }
    },
    updateCustomField(id) {
      const field = this.typeForm.customFields.find((f) => f.id === id);
      if (field.id && field.tag !== "created") {
        field.tag = "updated";
      }
    },
    async saveType() {
      this.wait = true;
      // Vérifie que les champs sont remplis
      if (!this.typeForm.name) {
        showToast("error", "Veuillez remplir tous les champs");
        return;
      }

      // Vérifie que les champs personnalisés sont remplis
      for (let i = 0; i < this.typeForm.customFields.length; i++) {
        if (
          this.typeForm.customFields[i].tag !== "deleted" &&
          !this.typeForm.customFields[i].name
        ) {
          showToast("error", "Veuillez remplir tous les champs personnalisés");
          return;
        }
      }

      let typeId = null;
      if (this.selectedType) {
        const updatedType = {
          data: {
            name: this.typeForm.name,
            description: this.typeForm.description
              ? this.typeForm.description
              : "",
          },
          id: this.selectedType.id,
        };
        await this.updateType(updatedType);
        typeId = this.selectedType.id;
      } else {
        // Création du type
        const newType = {
          name: this.typeForm.name,
          description: this.typeForm.description
            ? this.typeForm.description
            : "",
        };
        const createdType = await this.createType(newType);
        typeId = createdType.id;
      }

      // Traite les champs personnalisés avec un switch
      for (const field of this.typeForm.customFields) {
        switch (field.tag) {
          case "created":
            await this.createOrUpdateCustomField({
              name: field.name,
              type_id: typeId,
            });
            break;
          case "updated":
            await this.createOrUpdateCustomField({
              id: field.id,
              name: field.name,
              type_id: typeId,
            });
            break;
          case "deleted":
            await this.deleteCustomField(field.id);
            break;
          default:
            break;
        }
      }

      await Promise.all([this.getAllTypes(), this.getAllCustomFields()]);
      this.wait = false;
      this.types = this.getTypes;
      if (!this.selectedType) {
        this.typeForm = {
          name: "",
          description: "",
          customFields: this.typeForm.customFields,
        };
      }
    },
    openModal(type) {
      this.selectedType = type;
      this.typeForm = type
        ? { ...type, customFields: [] }
        : { name: "", description: "", customFields: [] };
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedType = null;

      // Reset the form
      this.typeForm = {
        name: "",
        description: "",
        customFields: [],
      };
    },
    async confirmDelete(typeId) {
      // Si une machine possède ce type empêche la suppression
      const machines = this.getMachines.filter(
        (machine) => machine.type_id === typeId
      );

      if (machines.length > 0) {
        showToast(
          "error",
          'La machine "' + machines[0].name + '" utilise ce type'
        );
        return;
      }
      await this.deleteType(typeId);
      await this.getAllTypes();
      this.types = this.getTypes;
    },
  },
  async mounted() {
    this.types = this.getTypes;
  },
};
</script>

<style scoped>
.type-manager {
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0 0 20px;
}

.list-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 0.5rem;
  border-bottom: 1px solid var(--color-border);
}

.header-item {
  flex: 1;
}

.header-item:last-child {
  flex: 0;
  text-align: right;
}

.type-list {
  list-style-type: none;
  padding: 0;
  max-height: 500px;
  overflow-y: auto;
}

.type-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.6rem 0;
  padding: 0.5rem;
  border: 1px solid var(--color-border);
  border-radius: 2px;
  cursor: pointer;
}

.type-item {
  flex: 1;
  overflow-y: hidden;
}

.type-item:last-child {
  flex: 0;
  text-align: right;
  overflow-y: visible;
}

.delete-button {
  background-color: red;
  color: white;
  border: none;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
}

.delete-button:hover {
  background-color: darkred;
}

.custom-field-list {
  list-style-type: none;
  padding: 0;
}

.custom-field-list li {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}

.custom-field-list input {
  margin-right: 10px;
  padding: 5px;
  border: 1px solid var(--color-border);
  border-radius: 2px;
}

.custom-field-list button {
  background-color: red;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 2px;
  margin-bottom: 10px;
  cursor: pointer;
}

.custom-field-list button:hover {
  background-color: darkred;
}

input,
select {
  margin-bottom: 10px;
  background-color: white;
  border-radius: 2px;
  padding: 10px;
  border: 1px solid var(--color-border);
  width: 94.5%;
}

input:focus,
select:focus {
  outline: none !important;
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 2px var(--color-primary);
}

.bottom-button {
  display: flex;
  justify-content: space-between;
}

.spinner {
  width: 12px;
  height: 12px;
}
</style>
