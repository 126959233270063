<template>
  <div class="right-panel" v-if="isReady">
    <div class="content">
      <div class="button-container">
        <button class="secondary-button-2" @click="saveTicket">
          {{ usingFor }}
        </button>
        <button
          class="secondary-button"
          v-show="usingFor !== 'Créer'"
          @click="removeTicket"
        >
          Supprimer
        </button>
        <button class="secondary-button" @click="cancel">Fermer</button>
      </div>
      <DetailedTicket :ticket="ticket" ref="detailedComponent" />
    </div>
  </div>
</template>

<script>
import DetailedTicket from "@/components/DetailedTicket.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { showConfirmDeleteTicket } from "@/sweetAlertService";

export default {
  name: "RightPanel",
  components: {
    DetailedTicket,
  },
  emits: ["rightPanelClosed"],
  props: {
    usingFor: {
      type: String,
      default: "Enregistrer",
    },
  },
  data() {
    return {
      ticket: {},
      isReady: false,
    };
  },
  computed: {
    ...mapGetters({
      getSelectedTicket: "getSelectedTicket",
      getTickets: "getTickets",
      getClients: "getClients",
      getTechnicians: "getTechnicians",
      getStatus: "getStatus",
      getPriority: "getPriority",
    }),
  },
  methods: {
    ...mapMutations({
      setSelectedTicket: "setSelectedTicket",
    }),
    ...mapActions({
      deleteTicket: "deleteTicket",
    }),
    // Récupère le ticket sélectionné
    getTicket() {
      this.isReady = false;
      if (this.usingFor === "Créer") {
        // Créer un nouveau ticket si aucun est sélectionner
        this.ticket = {
          title: "",
          description: "",
          client_id: {
            id: 0,
            name: "Aucun client",
          },
          summary: "",
          priority_id: this.getPriority[this.getPriority.length - 1].id,
          state_id: this.getStatus[0].id,
          technician_id: {
            id: this.getTechnicians[0].id,
            name: this.getTechnicians[0].name,
          },
          actions: [],
        };
        this.isReady = true;
      } else if (this.usingFor !== "closed") {
        this.ticket = this.getTickets.find(
          (ticket) => ticket.id === this.getSelectedTicket
        );
        this.isReady = true;
      }
    },
    // Déselectionne le ticket
    cancel() {
      this.setSelectedTicket(undefined);
      this.isReady = false;
      this.$emit("rightPanelClosed");
    },
    // Supprime le ticket sélectionné
    removeTicket() {
      showConfirmDeleteTicket(this.getSelectedTicket).then((result) => {
        if (result.isConfirmed) {
          this.deleteTicket(this.getSelectedTicket);
          this.cancel();
        }
      });
    },
    // Enregistre le ticket
    saveTicket() {
      if (this.usingFor === "Créer") {
        if (this.$refs.detailedComponent) {
          this.$refs.detailedComponent.create();
        }
      } else {
        if (this.$refs.detailedComponent) {
          this.$refs.detailedComponent.save();
        }
      }
    },
  },
  watch: {
    getSelectedTicket() {
      if (this.getSelectedTicket !== undefined) {
        this.getTicket();
      } else {
        this.isReady = false;
      }
    },
    usingFor() {
      this.getTicket();
    },
  },
};
</script>

<style scoped>
@media (max-width: 1180px) {
  .right-panel {
    position: static;
    width: 100% !important;
    height: auto;
    border-left: none;
  }
}

.right-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 25vw;
  min-width: 300px;
  overflow-y: auto;
  border-left: 1px solid var(--color-border);
  height: 100vh;
  margin-top: 4rem;
}

.content {
  padding: 1rem;
}

.button-container {
  position: sticky;
  top: 1rem;
  right: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  max-height: 100%;
  z-index: 200;
  background-color: var(--color-background);
}

.secondary-button-2 {
  margin-top: 10px;
  padding: 10px;
  width: 15vh;
  border: 1px solid var(--color-border-dark);
  border-radius: 0.2em;
  background-color: var(--color-background);
  color: var(--color-text);
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.secondary-button-2:hover {
  background-color: #eaeafa;
}
</style>
