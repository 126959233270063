<template>
  <main>
    <div class="home">
      <left-panel v-show="displayingLeftPanel" @requestNewTicket="requestNew" />
      <div
        v-show="
          getSelectedTicket !== undefined || usingRightPanelFor === 'Créer'
        "
        class="right-panel-placeholder"
        ref="rightPanelPlaceholder"
      ></div>
      <right-panel
        v-show="displayingRightPanel"
        :usingFor="usingRightPanelFor"
        ref="rightPanelRef"
        @rightPanelClosed="rightPanelClosed"
      />
    </div>
  </main>
</template>

<script>
import leftPanel from "@/components/LeftPanel.vue";
import RightPanel from "@/components/RightPanel.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "HomeView",
  components: {
    RightPanel,
    leftPanel,
  },
  data() {
    return {
      usingRightPanelFor: undefined,
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters(["getSelectedTicket"]),
    displayingRightPanel() {
      const isMobile = this.screenWidth < 1200;
      return (
        !isMobile ||
        this.getSelectedTicket !== undefined ||
        this.usingRightPanelFor === "Créer"
      );
    },
    displayingLeftPanel() {
      const isMobile = this.screenWidth < 1200;
      return (
        !isMobile ||
        this.getSelectedTicket === undefined ||
        this.usingRightPanelFor !== "Créer"
      );
    },
  },
  methods: {
    ...mapMutations(["setSelectedTicket"]),
    // Modifie les variables pour la création d'un nouveau ticket
    requestNew(text) {
      this.setSelectedTicket(undefined);
      this.usingRightPanelFor = text;

      // Force la mise à jour de rightPanelPlaceHolder
      this.$nextTick(() => {
        this.updateRightPanelWidth();
      });
    },
    // Gère le redimensionnement de la fenêtre
    handleResize() {
      this.screenWidth = window.innerWidth;
      this.updateRightPanelWidth();
    },
    updateRightPanelWidth() {
      this.$nextTick(() => {
        this.$refs.rightPanelPlaceholder.style.minWidth =
          this.$refs.rightPanelRef.$el.offsetWidth + "px";
      });
    },
    rightPanelClosed() {
      this.usingRightPanelFor = "closed";
    },
  },
  watch: {
    getSelectedTicket() {
      if (this.getSelectedTicket) {
        this.usingRightPanelFor = undefined;
      }

      this.updateRightPanelWidth();

      // Force la mise à jour de displayRightPanel
      this.$nextTick(() => {
        this.displayingRightPanel;
      });
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.updateRightPanelWidth();
  },
};
</script>

<style scoped>
.home {
  display: flex;
  justify-content: space-between;
  min-height: 93vh;
  overflow: hidden;
  width: 100%;
  padding: 0;
}

.right-panel-placeholder {
  width: 0;
  visibility: hidden;
}
</style>
