<template>
  <main>
    <div class="user-profile">
      <div class="left-panel">
        <h2>Modifier le profil</h2>
        <ul>
          <li
            :class="{ active: selectedPanel === 'email' }"
            @click="selectPanel('email')"
          >
            Modifier l'email
          </li>
          <li
            :class="{ active: selectedPanel === 'password' }"
            @click="selectPanel('password')"
          >
            Modifier le mot de passe
          </li>
        </ul>
      </div>
      <div class="right-panel">
        <div v-if="selectedPanel === 'email'">
          <h3>Modifier l'email</h3>
          <form @submit.prevent="handleEmailSubmit">
            <div class="form-group">
              <label for="email">Email:</label>
              <input
                type="email"
                v-model="userForm.email"
                id="email"
                required
              />
            </div>
            <div class="form-buttons">
              <button class="primary-button" type="submit">Enregistrer</button>
              <button
                class="primary-button"
                type="button"
                @click="clearSelection"
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
        <div v-if="selectedPanel === 'password'">
          <h3>Modifier le mot de passe</h3>
          <form @submit.prevent="handlePasswordSubmit">
            <div class="form-group">
              <label for="password">Nouveau mot de passe:</label>
              <input
                :type="inputType"
                v-model="userForm.password"
                id="password"
                minlength="4"
                required
              />
              <button class="input-icon" @click.prevent="togglePassword">
                {{ toggleBtnIcon }}
              </button>
            </div>
            <div class="form-group">
              <label for="confirmPassword">Confirmer le mot de passe:</label>
              <input
                :type="inputType2"
                v-model="userForm.confirmPassword"
                id="confirmPassword"
                minlength="4"
                required
              />
              <button class="input-icon" @click.prevent="togglePassword2">
                {{ toggleBtnIcon }}
              </button>
            </div>
            <div class="form-buttons">
              <button class="primary-button" type="submit">Enregistrer</button>
              <button
                class="primary-button"
                type="button"
                @click="clearSelection"
              >
                Annuler
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { showToast } from "@/sweetAlertService";

export default {
  name: "UserProfile",
  data() {
    return {
      showPassword: false,
      showPassword2: false,
      selectedPanel: "email",
      userForm: {
        email: "",
        password: "",
        confirmPassword: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getUser", "getTechnicians"]),
    inputType() {
      return this.showPassword ? "text" : "password";
    },
    inputType2() {
      return this.showPassword2 ? "text" : "password";
    },
    toggleBtnIcon() {
      return this.showPassword ? "👁️‍🗨️" : "👁️‍🗨️";
    },
  },
  methods: {
    ...mapActions(["updateMe"]),
    selectPanel(panel) {
      this.selectedPanel = panel;
      if (panel === "email") {
        this.userForm.email = this.getUser.user.email;
      } else if (panel === "password") {
        this.userForm.password = "";
        this.userForm.confirmPassword = "";
      }
    },
    clearSelection() {
      this.selectedPanel = "";
    },
    handleEmailSubmit() {
      // Vérifi que le champ contient bien un email
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.userForm.email)) {
        showToast("error", "Veuillez entrer un email valide");
        return;
      }

      // Vérifie que aucun technicien n'a le même email
      if (
        this.getTechnicians.some(
          (technician) => technician.email === this.userForm.email
        )
      ) {
        showToast("error", "Un technicien avec cet email existe déjà");
        return;
      }

      const data = { email: this.userForm.email };

      this.updateMe(data);
    },
    handlePasswordSubmit() {
      if (this.userForm.password !== this.userForm.confirmPassword) {
        showToast("error", "Les mots de passe ne correspondent pas");
        return;
      }

      const data = {
        password: this.userForm.password,
        password_confirmation: this.userForm.confirmPassword,
      };

      this.updateMe(data);
    },
    // Gère l'affichage du mot de passe
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    togglePassword2() {
      this.showPassword2 = !this.showPassword2;
    },
  },
  mounted() {
    this.userForm.email = this.getUser.user.email;
  },
};
</script>

<style scoped>
.user-profile {
  display: flex;
  max-width: 800px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.left-panel {
  flex: 1;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.right-panel {
  flex: 2;
  padding: 20px;
}

h3,
h2 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

input,
select {
  margin-bottom: 10px;
  background-color: white;
  border-radius: 2px;
  padding: 10px;
  border: 1px solid var(--color-border);
  width: 94.5%;
}

input:focus,
select:focus {
  outline: none !important;
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 2px var(--color-primary);
}

.input-icon {
  padding: 0.5rem;
  display: inline;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border: 0;
  background-color: inherit;
  cursor: pointer;
  width: fit-content;
  margin-left: -2rem;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

ul li:hover {
  background-color: #e9e9e9;
}

ul li.active {
  background-color: #ddd;
  color: #333;
  font-weight: bold;
}

@media (max-width: 1180px) {
  .user-profile {
    flex-direction: column;
  }

  .left-panel,
  .right-panel {
    border-right: none;
    margin-top: 0;
    padding: 0;
  }
}
</style>
