<template>
  <div class="left-panel">
    <div class="content">
      <div class="header-left-panel">
        <div class="box-filter">
          <div class="search-filter">
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Rechercher par titre..."
            />
          </div>
          <div class="technician-filter">
            <select v-model="selectedTechnician">
              <option value="">Tous les techniciens</option>
              <option
                v-for="tech in sortedTechnicians()"
                :key="tech.id"
                :value="tech.id"
              >
                {{ tech.name }}
              </option>
            </select>
          </div>
          <div class="status-filter">
            <select v-model="selectedStatus">
              <option
                v-for="state in getStatus"
                :key="state.id"
                :value="state.id"
              >
                {{ state.name }}
              </option>
            </select>
          </div>
        </div>
        <button
          class="primary-button"
          @click="$emit('requestNewTicket', 'Créer')"
        >
          Nouveau ticket
        </button>
      </div>
      <div v-for="tech in displayedTechnicians" :key="tech.id">
        <h2>{{ tech.name }}</h2>
        <div class="ticket-list">
          <li
            v-for="ticket in getTicketsForTechnician(tech.id)"
            :key="ticket.id"
            :class="{ selected: getSelectedTicket === ticket.id }"
            @click="selectTicket(ticket.id)"
          >
            <simplified-ticket :ticket-id="ticket.id" />
          </li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import simplifiedTicket from "@/components/SimplifiedTicket.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "LeftPanel",
  components: {
    simplifiedTicket,
  },
  emits: ["requestNewTicket"],
  data() {
    return {
      technicians: [],
      searchQuery: "",
      selectedTechnician: "",
      selectedStatus: "",
    };
  },
  computed: {
    ...mapGetters({
      getTechnicians: "getTechnicians",
      getSelectedTicket: "getSelectedTicket",
      getTickets: "getTickets",
      getStatus: "getStatus",
    }),
    displayedTechnicians() {
      if (this.selectedTechnician) {
        return this.getTechnicians.filter(
          (tech) => tech.id === this.selectedTechnician
        );
      }
      return this.sortedTechnicians();
    },
  },
  methods: {
    ...mapMutations(["setSelectedTicket"]),
    getTicketsForTechnician(techId) {
      return this.getTickets.filter(
        (ticket) =>
          ticket.technician_id.id === techId &&
          ticket.state_id === this.selectedStatus &&
          ticket.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    sortedTechnicians() {
      return this.getTechnicians.sort((a, b) => a.name.localeCompare(b.name));
    },
    // Sélectionne un ticket spécifique
    selectTicket(id) {
      this.setSelectedTicket(id);
    },
  },
  mounted() {
    this.selectedStatus = this.getStatus[0].id;
  },
};
</script>

<style scoped>
@media (max-width: 1180px) {
  .left-panel {
    width: 100% !important;
  }
}

.left-panel {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0;
}

.content {
  padding: 1rem;
}

.header-left-panel {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  padding-right: 10px;
}

.ticket-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.ticket-list li {
  margin: 0.6rem;
  padding: 0.5rem;
  border: 1px solid var(--color-border);
  border-radius: 2px;
  cursor: pointer;
  max-width: 300px;
}

.selected {
  border: 2px solid var(--color-primary) !important;
}

.search-filter input {
  padding: 0.5rem;
  margin-right: 1rem;
  width: 200px; /* ou ajustez selon l'espace disponible */
}

.client-filter p {
  display: inline;
  margin: 1rem;
}

.box-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

select {
  margin-right: 0.5em;
}

input,
select {
  background-color: white;
  border-radius: 2px;
  padding: 10px;
  border: 1px solid var(--color-border);
  width: 94.5%;
}

input:focus,
select:focus {
  outline: none !important;
  border-color: var(--color-primary) !important;
  box-shadow: 0 0 2px var(--color-primary);
}

.technician-filter select {
  width: 200px; /* ou ajustez selon l'espace disponible */
}
</style>
